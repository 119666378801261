
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { ApiHelper } from "../helpers/all";
import Loading from "../components/Common/Loading.vue";
import DateSelect from "../components/DateSelect.vue";
import directives from "../helpers/directives";
import Inputmask from "inputmask";

declare let dataURL: string;
declare const $: any;
declare const Stripe: any;
declare const window: any;
import SignaturePad from "signature_pad";
import Vue from "vue";
import moment from "moment";
import { dollarFormat, getGenderName, ParticipantStatus, phoneFormat } from "@/helpers/ApiHelper";
import {
  registerAllergies,
  registerMedication,
  registerTransportation,
  registerAddressBlock,
  registerParentGuardian,
} from "@/helpers/FbControls";
import PageHeader from "@/components/PageHeader.vue";
require("formBuilder/dist/form-render.min.js");

declare const bootstrap: any;

@Component({

  inheritAttrs: false,
  components: {
    PageHeader,
    Pagination,
    DateSelect,
    Loading
  },
  directives,
  async beforeRouteLeave(to, from, next) {
    const self: any = this;
    if(to.name != 'Login' && self.forcePayment && self.isShowingPayment && !self.doPayDone) {
      await self.showForcePaymentNote();
    }

    next();
  }
})
export default class BeginApplication extends TSXComponent<void> {
  $router: any;
  $campCartKey: any;
  $loggedUser: any;
  $route: any;
  $swal: any;
  $validator: any;
  cartItems: any = [];
  paymentInfo: any = {
    cartTotal: 0,
    chargeMonths: 0,
    recurringAmount: 0,
    cartTotalFormatted: "$0.00",
    recurringAmountFormatted: "$0.00"
  };
  siderbar: any = [];
  tabs: any = [];
  activeTabIndex = 0;
  roommate: any = [];
  app: any = {
    appID: 0,
    appJSON: "[]",
    appName: "",
    eventID: 0,
    pTypeID: 0,
    profileID: 0,
    eventTimes: "",
    profileInfo: "",
    profileName: "",
    participantID: 0,
    participantCost: 0,
    eventName: "",
    participantJSON: [],
    steps: [],
    pPaymentJson: {},
    isWaitList: false,
    isForcePayment: false,

    // profile details
    fName: "",
    lName: "",
    pPrevEmail: "",
    pEmail: "",
    pBirthdayY: "",
    pBirthdayM: "",
    pBirthdayD: "",
    pPhone: "",
    pGender: "",
    pAddress: "",
    city: "",
    state: "",
    zip: ""
  };
  formRenderInstance: any = {};
  formUserData: any = [];
  participantID = 0;
  percentComplete: any = {
    questionsNeeded: 0,
    questionsDone: 0,
    percentComplete: 0
  };
  eventID = 0;
  pTypeID = 0;
  profileID = 0;
  registrationStep = 0;
  allowEditApp = true;
  updateAppOnly = 0;
  loading = true;
  addOns: any = [];
  addOnsTotal = 0;
  addOnsTotalFormatted = "$0.00";
  addOnsTotalMonthly = 0;
  addOnsTotalMonthlyFormatted = "$0.00";
  selectedAddOns: any = [];
  totalFunds = "";
  years: any = [];
  months: any = [];
  days: any = [];
  dynamicAdditionalCosts: any = [];
  totalPercent = 0;
  questionsNeeded = 0;
  questionsDone = 0;
  birthday = "";
  $refs!: {
    birthdaySelect: DateSelect;
    pageHeader: any;
  };
  birthdaySelectUID = "";
  dynamicCostChangeTimeout: any = null;
  camStoreFund: any = {};
  inviteData = {
    isProcessing: false,
    code: '',
    email: '',
    emailError: '',
    firstName: '',
    firstNameError: '',
    lastName: '',
    lastNameError: '',
    valid: false,
    verified: false,
    linkActive: 0
  }
  isViewInvite = false;
  applications: any = [];
  forcePayment = false;
  totalCost = 0;
  totalCostFormatted = "";
  // paymentInfo: any = {};
  paymentInfoLoading = false;
  paymentType = "";
  paying = false;
  stripeToken = "";
  stripeLast4 = "";
  cardName = "";
  cardNumber = "";
  expMoth = "";
  expyear = "";
  cvc = "";
  stripeError = 0;
  zipcode = "";
  paymentErrMessage = "";
  cardInfoError = "";
  discountCode = "";
  applyingDiscount = false;
  availableDiscounts: any = [];
  discountsHasCode: any = [];
  form = {
    cardName: {
      value: "",
      error: ""
    },
    cardNumber: {
      value: "",
      error: ""
    },
    expMoth: {
      value: "",
      error: ""
    },
    expyear: {
      value: "",
      error: ""
    },
    cvc: {
      value: "",
      error: ""
    },
    zipcode: {
      value: "",
      error: ""
    },
  }
  callFrom = "";
  currentAppModuleId = "";
  appliedDiscount: any = [];
  autoDiscounts: any = [];
  financialSummary: any = {
    discountCode: "",
    appliedDiscount: [],
    availableDiscounts: []
  };
  doPayDone = false;
  balanceCredit = 0;
  balanceCreditFormatted = "";

  mounted() {
    const _root: any = this;
    window.fbControls = [];

    window.fbControls.push(function(controlClass) {
      class ControlConditionalOptions extends controlClass {
        build() {
          const id = this.id;
          const required = this.required || false;
          return `
            <div class="radio-group">
              <div class="formbuilder-radio form-check">
                <input name="${id}" id="${id}-0" ${required ? 'required="required" aria-required="true"' : ""} value="Yes" type="radio" class="form-check-input">
                <label for="${id}-0" class="form-check-label">Yes</label>
              </div>
              <div class="formbuilder-radio form-check">
                <input name="${id}" id="${id}-1" ${required ? 'required="required" aria-required="true"' : ""} value="No" type="radio" class="form-check-input">
                <label for="${id}-1" class="form-check-label">No</label>
              </div>
            </div>
          `;
        }
        onRender() {
          const id = this.id;
          const field = $(`.field-${id}`);
          const userData = this.config.userData || [];
          if (field.length) {
            field.addClass("formbuilder-radio-group");
            if ((userData[0] || "") != "") {
              field
                .attr("data-value", userData[0].toLowerCase())
                .find(`input[type=radio][value=${userData[0]}]`)
                .prop("checked", true);
            }

            field.find(`input[type=radio]`).each((i, obj) => {
              $(obj).on("click", () => {
                const val = `${$(obj)?.val() || ""}`.toLowerCase();
                field.attr("data-value", val);
                if (val == "no") {
                  // clear explain textbox
                  $(`#explain-${id}`).val("");
                }

                const ps: any = _root.$refs.todops || [];
                for (const item of ps) {
                  item.update();
                }
              });
            });
          }
        }
      }

      // register this control
      controlClass.register("conditionalOptions", ControlConditionalOptions);
      return ControlConditionalOptions;
    });

    window.fbControls.push((controlClass: any) => {
      class ControlSignature extends controlClass {
        build() {
          return `
          <div>
            <div class="signature-container signature-pad col-6 m-auto">
              <img src="${this.config.signature}" class="defaultImage"  height="65" width="352"  style="position: absolute;" />
              <canvas id="${this.config.name}"   height="65" width="352" style="touch-action: none; background: white;"></canvas>
              <div class="signatureButtons">
                <button class="btn-save"></button>
                <button class="btn-undo"></button>
                <button class="btn-clear"></button>
              </div>
              <div class="otherButtons">
                <label for="file_${this.config.name}" class="button SecondaryColor FontColor btn">Upload Signature</label>
                <input type="file" class="file-upload"
                 accept="image/x-png,image/gif,image/jpeg"
                 id="file_${this.config.name}" style="display: none"
                 target="${this.config.name}"
                 />
              </div>
            </div>
          </div>
        `;
        }
        onRender() {
          const canvas: any = document.getElementById(this.config.name);
          const signaturePad = new SignaturePad(
            canvas,
            {
              backgroundColor: "rgb(255, 255, 255)"
            }
          );
          const signatureCanvas = $('#'+this.config.name);
          const container = signatureCanvas.parents('.signature-container');
          if (this.config.signature) {
            signatureCanvas.attr('data-signature', this.config.signature);
            container.find('.defaultImage').show();
            container.find('.btn-undo').hide();
          } else {
            container.find('.defaultImage').hide();
            container.find('.btn-undo').show();
          }
          signaturePad.onEnd = async () => {
            container.find('.defaultImage').hide();
            const signatureData = signaturePad.toDataURL();
            signatureCanvas.attr('data-signature', signatureData)
            signatureCanvas.attr('data-new', 1);
            await _root.getTotalPercent();
          }


          container.find('.btn-save').on(
            'click',
            async () => {
              ApiHelper.showSuccessMessage('Saved');
              await _root.getTotalPercent();
            }
          );
          container.find('.file-upload').on(
            'change',
            async (e) => {
              const files = e.target.files || [];
              const _this = $(e.target);
              if (files.length > 0) {
                const signatureCanvas2 = $('#' + _this.attr('target'));
                const file = files[0];
                const base64 = await ApiHelper.convertFileToBase64(file);
                _root.loading = true;
                const result = await ApiHelper.apiPost('/uploadFileFromBase64', {
                  uuid: ApiHelper.getUuid(),
                  domain: ApiHelper.getDomain(),
                  base64: base64,
                  group: "profiles/" + _root.profileID + '/signatures',
                  id: _root.profileID,
                  data: {
                    fileName: file.name
                  }
                });
                _root.loading = false;
                container.find('.defaultImage').attr('src', '');
                if (result.status === 1) {
                  container.find('.defaultImage').attr('src', result.data.url).show();
                  container.find('.btn-undo').hide();
                  signatureCanvas2.attr('data-signature', result.data.url);
                  signatureCanvas2.attr('data-new', 0);
                  signaturePad.clear();
                }
                await _root.getTotalPercent();
              }
            }
          )
          container.find('.btn-undo').on(
            'click',
            async () => {
              const data = signaturePad.toData();
              if (data) {
                data.pop(); // remove the last dot or line
                signaturePad.fromData(data);
              }
              if (data.length > 0) {
                const signatureData = signaturePad.toDataURL();
                signatureCanvas.attr('data-signature', signatureData)
              } else {
                signatureCanvas.attr('data-signature', '');
              }
              signatureCanvas.attr('data-new', 1);
              await _root.getTotalPercent();
            }
          );
          container.find('.btn-clear').on(
            'click',
            async () => {
              container.find('.defaultImage').hide();
              container.find('.btn-undo').show();
              signaturePad.clear();
              signatureCanvas.attr('data-signature', '');
              signatureCanvas.attr('data-new', 1);
              await _root.getTotalPercent();
            }
          );

        }
      }
      controlClass.register("signature", ControlSignature);
      return ControlSignature;
    });

    window.fbControls.push((controlClass: any) => {
      class ControlDob extends controlClass {
        build() {
          const userData = this.config.userData || [];
          const dateString = userData.length > 0 ? userData[0] : "";
          const arr = dateString.split('-');
          let days: number[] = [];
          if (arr.length === 3) {
            const defaultYear = parseInt(arr[0]);
            const defaultMonth = parseInt(arr[1]);
            const defaultDay = parseInt(arr[2]);
            if (defaultYear && defaultMonth && defaultDay) {
              days = ApiHelper.getDaysList(defaultYear, defaultMonth);
            }

          }
          const months: number[] = [];
          for (let i = 1; i < 13; i++) {
            months.push(i);
          }
          if (days.length == 0) {
            for (let i = 1; i < 32; i++) {
              days.push(i);
            }
          }

          const years: number[] = [];
          for (let i = 1971; i < new Date().getFullYear() + 1; i++) {
            years.push(i);
          }
          const isSmallSize = (this.config.className || '').includes('col-3');
          return `
          <div
          id="date-${this.config.name}"
          data-userData="${JSON.stringify(this.config.userData)}"
          class="row form-group ${this.config.className} shadow-none pe-0 d-flex" style="padding:0; margin: 0; border: none">
            <input id="${this.config.name}" type="date" style="display: none" ${ (this.config.required || '') === 'required' ? 'aria-required="true" required="required"' : ''} />
            <div class="col-4 ps-0 pe-lg-0">
              <select type="text" class="form-select px-3 text-center sel-month">
                <option value="">${isSmallSize ? 'M' : 'Month' }</option>
                ${months.map(value => `<option value="${value}">${value}</option>`)}
              </select>
            </div>
            <div class="col-4 ps-1 pe-lg-0">
              <select class="form-select px-3 text-center sel-day">
                <option value="">${isSmallSize ? 'D' : 'Day' }</option>
                ${days.map(value => `<option value="${value}">${value}</option>`)}
              </select>
            </div>
            <div class="col-4 pe-0 ps-1">
              <select type="text" class="form-select px-3 text-center sel-year">
                <option value="">${isSmallSize ? 'Y' : 'Year' }</option>
                ${years.map(value => `<option value="${value}">${value}</option>`)}
              </select>
            </div>
          </div>
        `;
        }
        onRender() {
          const userData = this.config.userData || [];
          const dateString = userData.length > 0 ? userData[0] : "";
          const arr = dateString.split('-');
          if (arr.length === 3) {
            const defaultYear = parseInt(arr[0]);
            const defaultMonth = parseInt(arr[1]);
            const defaultDay = parseInt(arr[2]);
            if (defaultYear && defaultMonth && defaultDay) {
              $('#'+this.config.name).val(moment(dateString).format('YYYY-MM-DD'));
              $('#date-'+this.config.name).find('.sel-year').val(defaultYear);
              $('#date-'+this.config.name).find('.sel-month').val(defaultMonth);
              $('#date-'+this.config.name).find('.sel-day').val(defaultDay);
            }

          }
          $('#date-'+this.config.name).on('change', '.sel-month' , () => {
            const dateContainer = $('#date-' + this.config.name);
            const year = parseInt(dateContainer.find('.sel-year').val());
            const month = parseInt(dateContainer.find('.sel-month').val());
            const day = dateContainer.find('.sel-day').val();
            const isSmallSize = (this.config.className || '').includes('col-3');
            const days = ApiHelper.getDaysList(year, month);
            dateContainer.find('.sel-day').html(`<option value="">${isSmallSize ? 'D' : 'Day' }</option>
                ${days.map(value => `<option value="${value}">${value}</option>`)}`);
            dateContainer.find('.sel-day').val(day);
            if (month && year && day) {
              const newDate = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
              $('#'+this.config.name).val(newDate);
            } else {
              $('#'+this.config.name).val('');
            }
          });
          $('#date-'+this.config.name).on('change', '.sel-year,.sel-day' , () => {
            const dateContainer = $('#date-' + this.config.name);
            const year = dateContainer.find('.sel-year').val();
            const month = dateContainer.find('.sel-month').val();
            const day = dateContainer.find('.sel-day').val();
            if (month && year && day) {
              const newDate = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD');
              $('#'+this.config.name).val(newDate);
            } else {
              $('#'+this.config.name).val('');
            }
          });
        }
      }
      controlClass.register("dob", ControlDob);
      return ControlDob;
    });

    registerMedication();
    registerAllergies();
    registerTransportation();
    registerAddressBlock();
    registerParentGuardian();

    const $this = this;
    // this.birthdaySelectUID = this.$refs.birthdaySelect._uid || "";

    // bold profile details menu
    $("a[data-href=profileDetails]").addClass("font-weight-bold");

    // get total percent when change required input in form profile details
    // $("#profileDetails input[aria-required=true], #profileDetails select[aria-required=true]").change(function() {
    //   $this.getTotalPercent();
    // });
    const body = document.querySelector('body');
    if (body) {
      body.addEventListener('focusin', async function(event: any) {
        if(event.target.tagName.toLowerCase() === 'input' || event.target.tagName.toLowerCase() === 'textarea') {
          if($("input:focus").length != 0){
            var id = $("input:focus").closest("section[id]").attr("id");

            $("a[data-href]").removeClass("font-weight-bold");
            $("a[data-href='" + id + "']").addClass("font-weight-bold");
          }
        }

        // validate previous module if go to next module
        const moduleSection = $(event.target).closest("fieldset.app-module");
        if(moduleSection.length) {
          const entityAppModuleId = moduleSection.data("entityappmoduleid");
          if(entityAppModuleId != $this.currentAppModuleId) {
            const currentSection = $("fieldset.app-module[data-entityappmoduleid=" + ($this.currentAppModuleId || 0) + "]");
            if(currentSection.length && currentSection.prop("id") == "profile_details") {
              // validate profile detail section
              $this.$validator.validateAll();
              try {
                $this.$refs.birthdaySelect[0].$validator.validateAll();
              } catch (error) {
                // do nothing
              }
            }else {
              const tmpForm = $('#moduleBuilder' + $this.currentAppModuleId);
              if(tmpForm.length) {
                $this.validateStepForm(tmpForm);
              }
            }
            $this.currentAppModuleId = entityAppModuleId;
          }
        }
      });
    }
  }

  maxLengthCheck(e){
      let max = 999999999;

      if(e.target.tagName == "INPUT" && e.target.type == "text") {
        max = 255;
      }

      if(e.target.value.length > max) {
        const value = e.target.value;
        const newValue = value.slice(0, max);
        
        e.target.value = newValue;
      }

      this.check_charcount(e.target.value.length, e.target.id, max, e);
    }

    check_charcount(length, content_id, max, e) {
      if(e.which != 8 && length >= max) {
        e.preventDefault();
      }
    }

  async created() {
    const cartItems = await ApiHelper.syncCartItems();
    const pInCart = cartItems?.data?.pInCart || [];
    this.autoDiscounts = cartItems?.data?.autoDiscounts || [];

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('click', this.moduleClick);

    const query = this.$route.query;
    this.isViewInvite = query.invite != undefined && query.invite == 1 ? true : false;
    this.cartItems = await ApiHelper.loadSavedCart();
    await this.updateCart();

    if(typeof this.$route.params.participantId != "undefined" && parseInt(this.$route.params.participantId) > 0) {
      this.participantID = parseInt(this.$route.params.participantId);
    }else if(this.cartItems.length || this.isViewInvite) {
      if(typeof query.event != "undefined" && typeof query.type != "undefined" && typeof query.profile != "undefined") {
        this.eventID = query.event;
        this.pTypeID = query.type;
        this.profileID = query.profile;
      }else {
        let firstApp: any = this.cartItems[0];
        this.eventID = firstApp.eventID;
        this.pTypeID = firstApp.pType.participantTypeID;
        this.profileID = firstApp.profile.profileid;
      }
      // make sure item in cart
      const itemInCart = this.cartItems.find((item: any) =>
        item.eventID == this.eventID &&
        item.pType.participantTypeID == this.pTypeID &&
        item.profile.profileid == this.profileID
      );
      if(!itemInCart && this.isViewInvite == false) {
        this.eventID = 0;
        this.pTypeID = 0;
        this.profileID = 0;
      }
    }

    await this.fetchData();

    // check event settings
    // check if seeing a app in cart, or a app not in cart
    if(this.registrationStep == 0 || this.registrationStep == 1) {
      let eventIds = "";
      const appInCart = this.cartItems.find(item =>
        this.app.eventID == item.eventID &&
        this.app.pTypeID == item.pType.participantTypeID &&
        this.app.profileID == item.profile.profileid
      );
      if(appInCart) {
        eventIds = [... new Set(this.cartItems.map(item => item.eventID))].join(",");
      }else {
        eventIds = `${this.app.eventID}`;
      }
      const responseEventSettings = await axios.post(`${dataURL}/eventSettings`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        eventIds,
        settingType: "forcepayment"
      });
      if(responseEventSettings.data.status == 1) {
        this.forcePayment = responseEventSettings.data.data.forcePayment || false;
      }
    }else if(this.registrationStep > 1) {
      // case: the registration is paid, make sure to remove from current cart
      this.cartItems = this.cartItems.filter((item: any) => !(
        item.eventID == this.app.eventID && 
        item.pType.participantTypeID == this.app.pTypeID && 
        item.profile.profileid == this.app.profileID
      ));
      await this.updateCart(true, true);
    }

    // case user view in invitation, add item into cart
    if(this.isViewInvite) {
      const itemInCart = this.cartItems.find((item: any) =>
        item.eventID == this.eventID &&
        item.pType.participantTypeID == this.pTypeID &&
        item.profile.profileid == this.profileID
      );
      if(!itemInCart) {
        this.cartItems.push({
          eventID: this.eventID,
          profile: {
            profileid: this.profileID,
            p_fname: this.app.fName || "",
            p_lname: this.app.lName || "",
            p_logo: this.app.pLogo || "",
            age: this.app.pAge || ""
          },
          pType: {
            participantTypeID: this.pTypeID,
            participantTypeName: this.app.pTypeName || "",
            participantAmount: this.app.participantCost || 0,
            eventName: this.app.eventName,
            ParticipantAmount_formated: ApiHelper.dollarFormat(this.app.participantCost || 0)
          }
        });
        await this.updateCart(true, true);
      }
    }

    // this.forcePayment &&
    if((this.registrationStep == 0 || this.registrationStep == 1)) {
      // need to build applications list to load next if user is creating multi participants in cart
      await this.buildApplications(pInCart);

      // init applied discounts for financial summary of current app
      for(const item of this.applications) {
        this.loadAppAppliedDiscount(item);
      }
      const currentApp = this.getCurrentApp();
      this.financialSummary.appliedDiscount = currentApp.appliedDiscount || [];
      if(this.applications.length > 1) {
        const carousel = new bootstrap.Carousel("#participants-slide", {
          interval: false
        });
        this.loadToSlideContainsApp();
      }
    }

    if((query.destination || "") == "roommate_request" && (this.registrationStep > 1 || this.$route.name == "ViewApplication")) {
      // auto scroll to roomate request section
      if($("#appSideBar a[data-href=roommate_request]").length) {
        this.callFrom = "roommate_request";
        this.gotoStep("roommate_request");
      }

      // check link active of this request
      if(this.inviteData.code.trim() != "") {
        const inRoomMate = this.roommate.find(item => 
          item.inviteCode == this.inviteData.code.trim() && 
          item.eventId == this.app.eventID && item.pTypeId == this.app.pTypeID && item.toProfileId == this.app.profileID
        );
        if(inRoomMate) {
          this.inviteData.linkActive = inRoomMate.link_active || 0;
        }
      }
    }

    this.loading = false;
  }

  async gotoStep(stepName: string) {
    const $this = this;
    $("a[data-href]:not([data-href='" + stepName + "'])").removeClass("font-weight-bold");
    $("a[data-href=" + stepName + "]").addClass("clicked");
    window.removeEventListener('scroll', $this.handleScroll);

    if( $("#" + stepName).length) {
      $("a[data-href=" + stepName + "]").addClass("font-weight-bold");
      $('html, body').animate({
        scrollTop: $("#" + stepName).offset().top
      }, 300, function() {
        window.addEventListener('scroll', $this.handleScroll);
        setTimeout(function(){ $(".clicked").removeClass("clicked"); }, 800);
      });
    }
    else {
      window.addEventListener('scroll', $this.handleScroll);
    }
  }

  members: any[] = [];
  async getFamilyMembers(profileId: number) {
    // get people
    const responseMembers = await axios.post(`${dataURL}/familyMembers`, {
      familyID: this.$loggedUser.familyId,
      profileID: profileId,
      getGuardian: 1,
      getAll: true
    });
    if (responseMembers.data.status == 1) {
      this.members = responseMembers.data.data.members || [];
    }
  }

  async fetchData() {
    const query = this.$route.query;

    // load application
    if(this.participantID > 0 || (this.eventID > 0 && this.pTypeID > 0 && this.profileID > 0)) {
      let requestObj: any = {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        familyID: this.$loggedUser.familyId
      };
      if(this.participantID > 0) {
        requestObj.participantID = this.participantID;
      }else {
        requestObj.eventID = this.eventID;
        requestObj.pTypeID = this.pTypeID;
        requestObj.profileID = this.profileID;
      }
      if((query.code || "") != "") {
        requestObj.invitedCode = query.code.trim();
      }
      
      // this.loading = true;
      const response = await axios.post(`${dataURL}/app`, requestObj);
      if(response.data.status == 1) {
        this.activeTabIndex = 0;
        const app = response.data.data.app;
        this.registrationStep = app.pRegistrationStep || 0;
        if( app.pStatus > 0 )
        {
          this.allowEditApp = false;
        }
        if( this.registrationStep>1 )
        {
          this.updateAppOnly = 1;
        }

        const stateOptions = this.getStateOptions();
        let pState = app.pState || "";
        if (pState && !stateOptions.find((state) => {
          return state.id == pState
        })) {
          pState = "";
        }
        this.app = {
          ...this.app,
          limitAges: app.ages,
          appID: app.entityAppID,
          appName: app.entityApp_name,
          eventName: app.ev_name,
          eventID: app.eventID,
          pTypeID: app.participantTypeID,
          pTypeName: app.participant_typename || "",
          profileID: app.profileID,
          participantID: app.participantID,
          participantStatus: app.pStatus,
          fName: app.pFname,
          lName:app.pLname,
          pPrevEmail: app.pEmail || "",
          pEmail: app.pEmail || "",
          pPhone: app.pPhone || "",
          pGender: app.pGender || "",
          pAge: app.age || "",
          pLogo: app.pLogo || "",
          inviteCode: app.inviteCode,
          profileName: [app.pFname, app.pLname].join(" "),
          eventTimes: app.tsStartFormatted + ' - ' + app.tsEndFormatted,
          profileInfo: [app.pFname, app.pLname].join(" ") + (app.age ? ', ' + app.age : '') + (app.pGender ? ', ' + ApiHelper.getGenderName(app.pGender) : ''),
          participantCost: app.cost || 0,
          pPaymentJson: JSON.parse(app.participant_paymentJson || "{}"),
          pAddress: app.pAddress || "",
          city: this.replaceSpecialCharacters(app.pCity || ""),
          state: pState,
          zip: app.pZip || "",
          dobFormatted: app.dobFormatted || "",
          invitedCode: app.invitedCode || "",
          isPriorEvent: app.isPriorEvent || 0,
          isWaitList: app.isWaitList || false,
          ignoreWaitlist: app.ignoreWaitlist ? true : false,
          isForcePayment: !!parseInt(app.isForcePayment)
        };

        this.app.pBirthdayM = "";
        this.app.pBirthdayD = "";
        this.app.pBirthdayY = "";
        // const dob = moment(app.pDob).utc(true);
        if(this.app.dobFormatted != "")
        {
          const dobArray = this.app.dobFormatted.split("-");
          this.app.pBirthdayM = dobArray[1];
          this.app.pBirthdayD = dobArray[2];
          this.app.pBirthdayY = dobArray[0];
          this.birthday = `${this.app.pBirthdayY}/${this.app.pBirthdayM}/${this.app.pBirthdayD}`;
        }

        const appmenu = response.data.data.appMenu;
        this.tabs = [];
        this.siderbar = appmenu.map((item: any) => {
          item.stepkeyword = item.stepkeyword.replace(/[^a-zA-Z0-9]/g,'_');
          item.formRenderInstance = undefined;
          if (item.stepkeyword !== "camp_store") {
            this.tabs.push({
              id: item.stepkeyword,
              title: item.stepName,
            });
          }
          return item;
        });
        this.camStoreFund = appmenu.find(item => item.isSystem == 1 && item.stepkeyword == "camp_store");

        const roommate = response.data.data.roomMate;
        this.roommate = roommate.map((item: any) => {
          item.formRenderInstance = undefined;
          return item;
        });

        await this.$forceUpdate();

        // render forms
        for(const item of this.siderbar) {
          if((item.isSystem || false) == false) {
            if (!item.formRenderInstance) {
              const moduleBuilderId = `moduleBuilder${item.entityAppModuleId}`;
              const appModuleJson = JSON.parse(item.appModule_json || '[]');
              appModuleJson.map((control) => {
                if (control.type == 'file' && control.subtype == 'fineuploader') {
                  control.subtype = 'file';
                }
                if (control.label || '') {
                  control.label = ApiHelper.removeLastBr(control.label);
                }
              });

              item.formRenderInstance = await $(`#${moduleBuilderId}`).formRender(
                {
                  formData: JSON.stringify(appModuleJson),
                  notify: {
                    error: (message) => {
                      return message;
                    },
                    success: (message) => {

                      const files = $('#moduleBuilder' + item.entityAppModuleId).find('input[type=file]:not(.file-upload)');
                      files.each((index, value) => {
                        const $file = $(value);
                        $file.attr('accept', 'application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/x-png,image/gif,image/jpeg');
                        this.updateFilePreview($file);
                      });

                      $('#moduleBuilder' + item.entityAppModuleId).on('click', '.delete-file', (e) => {
                        const _this = $(e.target);
                        this.$swal({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          // icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete it!",
                          customClass: {
                            container: "swal2-container-custom",
                          }
                        }).then((result: any) => {
                          if (result.isConfirmed) {
                            const fileName = _this.attr('data-file');
                            const fileSelector = _this.parents('.form-group').find('input[type=file]');
                            const arrFiles = JSON.parse(fileSelector.attr('files') || '[]');
                            fileSelector.attr('files', JSON.stringify(arrFiles.filter((file) => {
                              return file.name != fileName;
                            })));
                            this.updateFilePreview(fileSelector);
                          }
                        });
                      });

                      $('#moduleBuilder' + item.entityAppModuleId).on('change', 'input[type=file]:not(.file-upload)', (e) => {
                        const _this = $('#' + e.target.id);
                        const files = e.target.files || [];
                        const arrFiles = JSON.parse(_this.attr('files') || '[]');
                        for(const file of files) {
                          if(file.size > 5 * 1024 * 1024) {
                            ApiHelper.showErrorMessage(file.name + ' is greater than 5MB. Please use another file', 'Oops');
                          } else {
                            if (file) {
                              this.loading = true;
                              ApiHelper.uploadFile(file.name, file).then((result: any) => {
                                _this.attr('file-path', result.filePath);
                                _this.attr('file-name', file.name);
                                arrFiles.push({
                                  path: result.filePath,
                                  name: file.name
                                });
                                _this.attr('files', JSON.stringify(arrFiles));
                                _this.val(undefined);
                                this.updateFilePreview(_this);
                                this.loading = false;
                              }).catch((error) => {
                                ApiHelper.showErrorMessage(JSON.stringify(error), 'Oops');
                                this.loading = true;
                              });
                            }
                          }
                        }
                      });

                      $('#moduleBuilder' + item.entityAppModuleId).find('.formbuilder-radio input[type=radio]').removeClass('form-control').addClass('form-check-input');
                      $('#moduleBuilder' + item.entityAppModuleId).find('.formbuilder-radio label').addClass('form-check-label');
                      $('#moduleBuilder' + item.entityAppModuleId).find('.formbuilder-radio').addClass('form-check');
                      $('#moduleBuilder' + item.entityAppModuleId).find('.formbuilder-radio-inline label, .formbuilder-checkbox label, .formbuilder-checkbox-inline label').addClass('form-check-label');
                      $('#moduleBuilder' + item.entityAppModuleId).find('.formbuilder-radio-inline input[type=radio], .formbuilder-checkbox input[type=checkbox], .formbuilder-checkbox-inline input[type=checkbox]').addClass('form-check-input');
                      $('#moduleBuilder' + item.entityAppModuleId).find('.formbuilder-radio-inline, .formbuilder-checkbox-inline, .formbuilder-checkbox').addClass('form-check');
                      $('#moduleBuilder' + item.entityAppModuleId).on('change input', 'input,textarea,select', (e) => {
                        const parent = $(e.target).parents('.form-group');
                        parent.find('.text-danger').removeClass('text-danger');
                        parent.find('.border-danger').removeClass('border-danger');
                        parent.removeClass('text-danger');
                      });
                      $('#moduleBuilder' + item.entityAppModuleId).on('paste blur focusout', 'input,textarea', (e) => {
                        let value = $(e.target).val();
                        value = value.trim();
                        $(e.target).val(ApiHelper.stripTags(value));
                      });
                      $('#moduleBuilder' + item.entityAppModuleId).on('focus', 'input,textarea', (e) => {
                        $('.registration_cart_btn.show').click();
                      });

                      return message;
                    },
                    warning: (message) => {
                      return message;
                    }
                  }
                }
              ); // appModule_json
              this.onFormChange(moduleBuilderId);

              // see this .rendered-form as a row
              const renderedForm = $(`#${moduleBuilderId} > .rendered-form:first-child`);
              if(renderedForm.length) {
                renderedForm.addClass("row");
                this.applyCustomWidth(renderedForm);
              }

              // apply custom sub labels for text fields
              const fieldsHaveSubLabels = appModuleJson.filter(t => (t.type || "") == "text" && (t.subLabel || "").trim() != "");
              if(fieldsHaveSubLabels.length) {
                for(const fieldData of fieldsHaveSubLabels) {
                  const field = $(`#${moduleBuilderId} .formbuilder-text.field-${fieldData.name}`);
                  if(field.length == 0) continue;
                  const fieldLabel = field.find("label.formbuilder-text-label");
                  if((fieldData.label || "").trim() != "" || (fieldData.required || false)) {
                    fieldLabel.addClass("has-label-text");
                  }
                  fieldLabel.append(`<span class="formbuilder-text-sublabel">(${fieldData.subLabel})</span>`);
                }
              }
            }
          }else {
            this.onFormChange(item.stepkeyword);
          }
        }

        if(this.$refs.birthdaySelect != undefined) {
          this.birthdaySelectUID = this.$refs.birthdaySelect[0]?._uid || "";
        }
        await this.getTotalPercent();

        // apply mask
        // zipcode
        $("input[inputmask=phonenumber]").each(function(i, obj) {
          Inputmask("(999) 999-9999", {
            autoUnmask: true
          }).mask(obj);
        });
        // phone
        $("input[inputmask=zipcode]").each(function(i, obj) {
          Inputmask({ regex: "\\d*" }).mask(obj);
        });

        // disable all forms if paid or partial paid
        if (this.registrationStep > 1 || ((this.app.isWaitList || false) && !this.app.ignoreWaitlist) || this.isCompletedFreeRegistration) {
          for(const module of this.siderbar) {
            $(`#moduleBuilder${module.entityAppModuleId}`).find('input, select, textarea').prop('disabled', true);
          }
          // disable profile details form
          $(`#profileDetails`).find('input, select, textarea').prop('disabled', true);

          // disable total funds
          $("#totalFunds").prop('disabled', true);

          // disable roommate request section if is waitlist
          if((this.app.isWaitList || false)) {
            $("#dormRequest").find("input, button").prop('disabled', true);
            $(".financial-summ-section").find("input").prop('disabled', true);
          }
        }

        if (this.$route.query.step) {
          await this.$nextTick()
          $(`a[href='#${this.$route.query.step}']`).focus()
        }

        // this.getCartTotal();
      }else if(response.data.message) {
        const errorCode = response.data?.errorCode || "";
        if(["participant_not_found", "inactive_event"].includes(errorCode)) {
          if(errorCode == "inactive_event") {
            // update cart
            const eventId = response.data.eventId;
            this.cartItems = this.cartItems.filter((item: any) => item.eventID != eventId);
            this.removeParticipant(this.participantID);
          }

          ApiHelper.showErrorMessage(response.data.message);
          this.$router.push({ name: "Registration" });
        }
      }

      //auto fill invited code from mailer 
      if (typeof this.$route.query.code != "undefined") {
        this.inviteData.code = this.$route.query.code || "";
      }
      else if(this.app.invitedCode != "undefined")
      {
        this.inviteData.code = this.app.invitedCode || "";
      }
      // this.loading = false;
    }

    // get registration step of items in cart
    this.totalFunds = this.app.pPaymentJson?.totalFunds || "";
    const participantIDs = this.cartItems.map((item: any) => item.participantID || 0).filter((id: any) => id > 0);
    if(participantIDs.length) {
      const response = await axios.post(`${dataURL}/getRegistrationStep`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        participantIDs: participantIDs.join(",")
      });
      if(response.data.status == 1) {
        const steps = response.data.data.registrationStep || [];
        for(const item of this.cartItems) {
          if((item.participantID || 0) > 0) {
            const currentStep = steps.find((p: any) => p.participantID == item.participantID);
            if(currentStep) {
              item.pRegistrationStep = currentStep.pRegistrationStep || 0;
            }
          }
        }
        this.$forceUpdate();
      }
    }

    // get add ons for this participant
    if(this.app.eventID) {
        const response = await axios.post(`${dataURL}/getAdditionalCosts`, {
            domain: ApiHelper.getDomain(),
            uuid: ApiHelper.getUuid(),
            eventIDs: `${this.app.eventID}`,
            hasDeposit: false
        });
        const additionalCosts = response.data.data.additionalCosts || [];
        const staticAdditionalCosts = response.data.data.staticAdditionalCosts || [];
        const dynamicAdditionalCosts = response.data.data.dynamicAdditionalCosts || [];

        this.addOns = staticAdditionalCosts.filter((item: any) => {
            return item.eventId == this.app.eventID &&
                (item.ptypeId == 0 || item.ptypeId == this.app.pTypeID);
        });
        this.dynamicAdditionalCosts = dynamicAdditionalCosts.filter((item: any) => {
            return item.eventId == this.app.eventID &&
                (item.ptypeId == 0 || item.ptypeId == this.app.pTypeID);
        });

        // reload selected addOns
        const savedSelectedAddOns = this.app.pPaymentJson?.selectedAddOns || [];
        if(savedSelectedAddOns.length && (this.addOns.length || this.dynamicAdditionalCosts.length)) {
          if(this.addOns.length) {
            for(const cost of this.addOns) {
                const inList = savedSelectedAddOns.find((item: any) => item.addonServiceId == cost.addonServiceId);
                if(inList) {
                  cost.validDate = 1;
                  this.toggleSelectedAddOns(inList);
                }
            }
          }
          if(this.dynamicAdditionalCosts.length) {
            for(let cost of this.dynamicAdditionalCosts) {
                const inList = savedSelectedAddOns.find((item: any) => item.addonServiceId == cost.addonServiceId);
                if(inList) {
                    Object.assign(cost, inList);
                    this.toggleSelectedAddOns(inList);
                }
            }
          }
        }

        if(this.addOns.filter((item) => {
          return item.validDate == 1;
        }).length || this.dynamicAdditionalCosts.length) {
          this.tabs.push({
            id: 'addOns',
            title: 'Add Ons',
          });
        }
        if (this.camStoreFund) {
          this.tabs.push({
            id: 'camp_store',
            title: 'Camp Store',
          });
        }
    }

    $("input#fName").val($(".appUserName").attr("data-fname"));
    $("input#lName").val($(".appUserName").attr("data-lname"));

    setTimeout(() =>{
      $('.circlechart').html(this.makesvg($('.circlechart').attr("data-percentage"), $('.circlechart').attr("data-tab"), parseInt($('.circlechart').attr("data-total")) + 1));
    }, 1);
    // load members
    await this.getFamilyMembers(this.app.profileID);
    await this.getTotalPercent();

    // Get all input elements with class="CLASS_NAME"
    const inputs = document.querySelectorAll('input');

    // Iterate over elements and add each one of them a `focus` listener
    inputs.forEach(input => {
      let $this = this;
      "keydown keyup paste".split(" ").forEach(function(e){
        input.addEventListener(e,$this.maxLengthCheck);
      });
    })
  }

  @Watch('$route', { immediate: true, deep: true })
  async stepChanged(newUrl, currentUrl) {
    if (newUrl?.query?.step === currentUrl?.query?.step) {
      return;
    }

    $(`a[href='#${newUrl.query.step}']`).focus()
  }

  avatarText(fName = "", lName = "") {
    fName = !fName ? "" : fName;
    lName = !lName ? "" : lName;
    return [fName.charAt(0), lName.charAt(0)].join("").toUpperCase();
  }

  async updateCart(getPaymentInfo = true, save = false) {
    let eventIDs: any = [];
    let total = 0;
    if(this.cartItems.length) {
      if(getPaymentInfo) {
        for(const item of this.cartItems) {
          // paticipant cost after included auto discount
          let participantCost = item.pType.participantAmount;
          const discount = this.autoDiscounts.find(discount => discount.eventId == item.eventID && discount.participant_typeId == item.pType.participantTypeID);
          if(discount) {
            const discountAmount = discount.discountAmount || 0;
            if(discountAmount > 0) {
              participantCost -= discountAmount;
              participantCost = parseFloat(participantCost.toFixed(2));
            }
          }

          total += participantCost;
          if(!eventIDs.includes(item.eventID)) {
            eventIDs.push(item.eventID);
          }
        }
        const response = await axios.post(`${dataURL}/getPaymentInfo`, {
          domain: ApiHelper.getDomain(),
          uuid: ApiHelper.getUuid(),
          familyID: this.$loggedUser.familyId,
          eventIDs: eventIDs.join(","),
          total: total,
          hasDeposit: false,
          participantIDs: this.cartItems
            .filter((item: any) => (item.participantID || 0) > 0)
            .map((item: any) => item.participantID)
            .join(",")
        });
        if(response.data.status == 1) {
          const paymentInfo = response.data.data.paymentInfo;
          this.paymentInfo.cartTotal = paymentInfo.participantAmount || 0;
          this.paymentInfo.chargeMonths = paymentInfo.chargeMonths || 0;
          this.paymentInfo.recurringAmount = paymentInfo.recurringAmount || 0;
          this.paymentInfo.cartTotalFormatted = paymentInfo.participantAmountFormatted || "";
          this.paymentInfo.recurringAmountFormatted = paymentInfo.recurringAmountFormatted || "";

          // update additional costs for items in cart
          this.cartItems = this.cartItems.map((item: any) => ({
            ...item,
            totalAdditionalCosts: item.totalAdditionalCosts || 0,
            totalCost: item.totalCost || item.pType.participantAmount || 0,
            totalCostFormatted: item.totalCostFormatted || item.pType.ParticipantAmount_formated || ""
          }));
          const additionalCosts = response.data.data.additionalCosts || [];
          if(additionalCosts.length) {
            let cartTotalAdditionalCosts = 0;
            const participantsInfo = response.data.data.participantsInfo || [];
            for(const item of this.cartItems) {
              let totalAdditionalCosts = 0;
              let tmp = [];
              let pPaymentJson: any = {};
              if((item.participantID || 0) > 0) {
                const pInfo = participantsInfo.find((p: any) => p.participantID == item.participantID);
                if(!pInfo) continue;
                pPaymentJson = JSON.parse(pInfo.participant_paymentJson || '{}');
                const selectedAddOns = pPaymentJson.selectedAddOns || [];

                tmp = additionalCosts.filter((c: any) => {
                  const inSelected = selectedAddOns.find(
                    (selected: any) =>
                      c.addonServiceId == selected.addonServiceId,
                  );
                  if(
                    c.eventId == item.eventID &&
                    (c.ptypeId == 0 || c.ptypeId == item.pType.participantTypeID) &&
                    inSelected
                  ) {
                    Object.assign(c, inSelected);
                    return true
                  }
                  return false;
                });
              }
              if(tmp.length) {
                totalAdditionalCosts = tmp
                  .map((c: any) => c.cost)
                  .reduce((totalAdditionalCosts, cost) => totalAdditionalCosts + cost);
                totalAdditionalCosts = parseFloat(totalAdditionalCosts.toFixed(2));
              }
              // include total funds
              totalAdditionalCosts += pPaymentJson.totalFunds || 0;

              item.addOns = tmp;
              item.totalAdditionalCosts = totalAdditionalCosts;
              item.totalCost = item.pType.participantAmount + item.totalAdditionalCosts;
              item.totalCost = parseFloat(item.totalCost.toFixed(2));
              item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
              cartTotalAdditionalCosts += totalAdditionalCosts;
            }
            this.paymentInfo.cartTotal += cartTotalAdditionalCosts;
            this.paymentInfo.cartTotalFormatted = ApiHelper.dollarFormat(this.paymentInfo.cartTotal);
          }
        }
      }
    }else {
      this.resetPaymentInfo();
    }

    // save cart
    if(save) {
      localStorage[this.$campCartKey] = JSON.stringify({
        cartItems: this.cartItems
      });
    }
  }

  handleScroll (event) {
    var sticky = $('#appSideBar'),
    scroll = $(window).scrollTop();
    const scrollEnd = scroll + $(window).height() > $(document).height() - 30;

    if (scroll >= 375) {
      /* $("#sideBar_percent_wrapper").html($(".mobileHide .application_cost_wrapper.progress_bar-section")); */
      sticky.addClass('position-fixed');
    }
    else {
     /*  $(".page_title .col-4").prepend($(".mobileHide .application_cost_wrapper.progress_bar-section")); */
      sticky.removeClass('position-fixed');
    }

    if(sticky.hasClass("position-fixed") && $(".clicked").length == 0){
        const bottomLink = $("#appSideBar a[data-href]").last();
        $('#appSideBar a').each(function (i, obj) {
            const currLink = $(obj);
            if(currLink && currLink.attr("data-href") != undefined){
                const refElement = "#" + currLink.attr("data-href");

                if($(".clicked").length && $(".clicked").length > 0) {
                  if(currLink.hasClass("clicked")){
                    currLink.addClass("font-weight-bold");
                  }
                }
                else if (refElement != "#" && $(refElement).length && $(refElement).offset().top <= scroll && $(refElement).offset().top + $(refElement).height() > scroll) {
                    currLink.addClass("font-weight-bold");
                }
                else {
                    currLink.removeClass("font-weight-bold");
                }
            }
        });

        if(scrollEnd && bottomLink.length && !bottomLink.hasClass("font-weight-bold")) {
          $("#appSideBar a[data-href]").removeClass("font-weight-bold");
          bottomLink.addClass("font-weight-bold");
        }
    }
     else{
      $(".clicked").removeClass("clicked");
    }
  }

  moduleClick (event) {
    if($("section[id]:hover").length != 0){
      $("a[data-href]").removeClass("font-weight-bold");
      $("a[data-href='" + $("section[id]:hover").attr("id") + "']").addClass("font-weight-bold").click();
    }
  }

  resetPaymentInfo() {
    this.paymentInfo.cartTotal = 0;
    this.paymentInfo.chargeMonths = 0;
    this.paymentInfo.recurringAmount = 0;
    this.paymentInfo.cartTotalFormatted = "$0.00";
    this.paymentInfo.recurringAmountFormatted = "$0.00";
  }

  async saveProgress() {
    // just save and add to waitlist if the case is waitlist
    if((this.app.isWaitList || false) && !this.app.ignoreWaitlist) {
      await this.saveParticipant();
      // finished registration, remove related item in cart
      if (this.cartItems.length) {
        this.cartItems = this.cartItems.filter((cartItem: any) => !(
          cartItem.eventID == this.app.eventID &&
          cartItem.profile.profileid == this.app.profileID &&
          cartItem.pType.participantTypeID == this.app.pTypeID
        ));
        ApiHelper.updateCartItems({ cartItems: this.cartItems });
        await ApiHelper.updateProfileStats();
      }
      this.$router.replace({ name: "Registration" });
      return;
    }

    let minAge = 0;
    let maxAge = 0;
    let memberAge = this.app.pAge || 0;
    const profileDetailModule = this.siderbar.find((module: any) => module.stepkeyword == "profile_details");
    if (profileDetailModule) {
      const [birthdayValid] = await Promise.all([
        this.$refs.birthdaySelect[0].$validator.validateAll(),
        this.$validator.validateAll()
      ]);
      memberAge = birthdayValid ? moment().diff(this.birthday, 'years') : 0;
    }

    const eventAges = this.app.limitAges || '';
    const arr = (eventAges || '').split('-');
    let hasAgeRange = false;
    if (arr.length == 2) {
      minAge = Number.parseInt(arr[0], 10) || 0;
      maxAge = Number.parseInt(arr[1], 10) || 0;
      hasAgeRange = true;
    } else if (eventAges) {
      const limitAge = Number.parseInt(eventAges, 10) || 0;
      if (limitAge > 0) {
        minAge = limitAge;
      }
    }
    let ageError = '';
    
    if(hasAgeRange && minAge > 0 && maxAge == 0) {
      // format e.g: 25- (participant age should less than 25)
      if(memberAge > minAge) {
        ageError = "Age range: <strong>" + eventAges + '</strong>; Age should be less or equal than <strong>' + minAge + '</strong>, would you like to proceed?';
      }
    }else {
      if (minAge) {
        if (memberAge < minAge) {
          ageError = "Age range: <strong>" + eventAges + '</strong>; Age should be greater than <strong>' + minAge + '</strong>, would you like to proceed?';
        }
      }
      if (maxAge) {
        if (memberAge > maxAge) {
          ageError = "Age range: <strong>" + eventAges + '</strong>; Age should be less than <strong>' + maxAge + '</strong>, would you like to proceed?';
        }
      }
    }

    if (ageError != '') {
      if (this.inviteData.code) {
        ApiHelper.showErrorMessage(`<strong>${this.app.profileName}</strong> is outside of the age range for <strong>${this.app.eventName}</strong>`)
        return;
      }
      const isConfirmed = await Vue.swal({
        title: "Are you sure?",
        html: ageError,
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
        customClass: {
          container: "swal2-container-custom",
        }
      }).then(result => {
        return result.isConfirmed;
      });
      if (!isConfirmed) {
        return;
      }
    }

    $('.registration_cart_btn.show').click();
    // collect participant json data same as singup page when user fill data for app
    const form = $("#formBuilder");
    const jsonObj: any = [];
    let valid = true;
    let validate = true;
    if (validate) {
      validate = this.validateForm();
    }
    // validate profile details
    const pDetailsModule = this.siderbar.find(item => item.isSystem == 1 && item.stepkeyword == "profile_details");
    if(pDetailsModule) {
      this.$validator.validateAll();
      this.$refs.birthdaySelect[0].$validator.validateAll();
    }

    // don't allow to save if user clears first name/last name
    if(this.$validator.errors.has("fNameInput") || this.$validator.errors.has("lNameInput")) {
      // show warning message
      let message = "";
      let fNameValid = true;
      let lNameValid = true;
      let scrollToPosition = 0;
      if(this.$validator.errors.has("fNameInput")) {
        message = "First Name is required!";
        fNameValid = false;
        scrollToPosition = $("#fNameInput").offset().top;
      }else if(this.$validator.errors.has("lNameInput")) {
        message = "Last Name is required!";
        lNameValid = false;
        scrollToPosition = $("#lNameInput").offset().top;
      }
      await this.$swal.fire({
        // icon: "warning",
        text: message,
        confirmButtonText: "OK",
        showCloseButton: true,
        customClass: {
          container: "swal2-container-custom",
          confirmButton: "btn button SecondaryColor FontColor"
        }
      });
      $('html, body').animate({
        scrollTop: scrollToPosition - 70
      }, 500, function() {
        setTimeout(() => {
          if(fNameValid == false) {
            $("#fNameInput").focus();
          }else if(lNameValid == false) {
            $("#lNameInput").focus();
          }
        }, 100);
      });
      valid = false;
      return false;
    }

    if(this.$validator.errors.has("emailInput")) {
      $('html, body').animate({
        scrollTop: $("#emailInput").offset().top - 70
      }, 500, function() {
        setTimeout(() => {
          $("#emailInput").focus();
        }, 300);
      });
      return false;
    }

    // this.app.steps = [];
    let stepsComplete = true;
    const finalSiderbar = this.siderbar
      .filter((item: any) => (item.isSystem || false) == false);
    this.app.steps = [];
    this.loading = true;
    for (const item of finalSiderbar) {
      const tmpForm = $('#moduleBuilder' + item.entityAppModuleId);
      this.validateStepForm(tmpForm);
      // const userData = this.getUserData(tmpForm, item.formRenderInstance);
      const userData = await this.getUserData(tmpForm, item);
      const percentComplete = this.getPercentStepComplete(item.formRenderInstance);
      if (percentComplete.percentComplete !== 100) {
        stepsComplete = false
      }
      this.app.steps.push(
        {
          percentComplete: percentComplete,
          entityAppModuleId: item.entityAppModuleId,
          moduleId: item.moduleId,
          json: JSON.stringify(userData.json)
        }
      );
    }

    const data = this.formRenderInstance.userData;
    form.find(".form-group:not(.answer):not(.blank)").each(function (i, val) {
      const formItem: any = $(val);
      var item: any = {};
      item ["label"] = formItem.find("label").html().split("<span")[0];

      if (formItem.hasClass("formbuilder-radio-group")) {
        item ["field"] = formItem.find("input[type=radio]:checked").attr("name");
        item ["value"] = formItem.find("input[type=radio]:checked").val();
      }
      else if (formItem.hasClass("formbuilder-checkbox-group")) {
        item ["field"] = formItem.find("input").attr("name").replace("[]", "");
        const theField = data.find((f: any) => f.name == item.field);
        theField.values.map((checkbox: any, key: number) => {
          const isChecked = $('#' + item.field + '-' + key).is(':checked') ;
          checkbox.selected = isChecked;
        });
        if(theField) {
          item.userData = theField.userData;
          item.values = theField.values;
        }
      }
      else if (formItem.hasClass("formbuilder-textarea")) {
        item ["field"] = formItem.find("textarea").attr("name");
        item ["value"] = formItem.find("textarea").val();
      }
      else if (formItem.hasClass("formbuilder-select")) {
        item ["field"] = formItem.find("select").attr("name");
        item ["value"] = formItem.find("option:selected").val();
      }
      else {
        item ["field"] = formItem.find("input").attr("name");
        item ["value"] = formItem.find("input").val();
      }

      jsonObj.push(item);
    });

    // save participant
    this.app.participantJSON = jsonObj;
    this.getTotalPercent();
    let saveParticipantResult = false;
    if(this.totalPercent < 100) {
      this.loading = false;
      // show warning message
      const confirm = await this.$swal.fire({
        // icon: "warning",
        text: "You have not completed the application yet",
        confirmButtonText: "SAVE PROGRESS",
        showCancelButton: true,
        cancelButtonText: "Return to Application",
        showCloseButton: true,
        focusCancel: true,
        customClass: {
          container: "swal2-container-custom swal2-incomplete-app",
          confirmButton: "btn button SecondaryColor FontColor",
          cancelButton: "cancel-btn",
        }
      });
      if(confirm.isConfirmed) {
        // warning that user need to complete app and pay to active this force pay registration
        if(this.app.isForcePayment) {
          await this.$swal.fire({
            text: "Please note your registration will not be active until your application has been completed and a payment has been made.",
            customClass: {
              container: "swal2-container-custom",
              confirmButton: "btn button SecondaryColor FontColor",
            }
          });
        }

        // still save progress
        saveParticipantResult = await this.saveParticipant();
      }else {
        this.scrollToFirstError();
      }
    }else {
      saveParticipantResult = await this.saveParticipant();
    }

    // load next application if any
    if(saveParticipantResult == true) {
      /*
      if (this.app.ignoreWaitlist) {
        if (this.cartItems.length) {
          this.cartItems = this.cartItems.filter((cartItem: any) => !(
            cartItem.eventID == this.app.eventID &&
            cartItem.profile.profileid == this.app.profileID &&
            cartItem.pType.participantTypeID == this.app.pTypeID
          ));
          ApiHelper.updateCartItems({ cartItems: this.cartItems });
          await ApiHelper.updateProfileStats();
        }

        this.$router.replace({ name: "Registration" });
        return false;
      }
      */

      // if is free registration, back to registration page
      let freeApp = {
        profileId: 0,
        eventId: 0,
        pTypeId: 0
      };
      if(this.totalPercent == 100 && this.isFreeRegistration) {
        const currentApp = this.getCurrentApp();
        freeApp = {
          profileId: currentApp.profileId,
          eventId: currentApp.eventId,
          pTypeId: currentApp.pTypeId
        };
        await this.removeCurrentAppOutCart();
        if(this.applications.length == 1) {
          this.$router.replace({ name: "Registration" });
          return;
        }
      }

      // && !(this.app.isWaitList || false)
      if(this.forcePayment && this.totalPercent == 100) {
        const currentApp = this.saveCurrentAppState();
        if(currentApp) {
          currentApp.done = true;
          // currentApp.appliedDiscount = this.financialSummary.appliedDiscount || [];
        }

        // update participantId for applications
        this.applications = this.applications.map(item => {
          const inCart = this.cartItems.find(cartItem =>
            cartItem.eventID == item.eventId &&
            cartItem.pType.participantTypeID == item.pTypeId &&
            cartItem.profile.profileid == item.profileId
          );
          if(inCart) {
            item.participantId = inCart.participantID || 0;
          }
          return item;
        });

        // load next application if any
        const notFinishedApp = this.applications.filter(
          (item: any) => (item.done || false) == false
        );
        if (notFinishedApp.length) {
          // load next
          this.resetParticipantInfo();
          this.participantID = notFinishedApp[0].participantId || 0;
          this.eventID = notFinishedApp[0].eventId;
          this.pTypeID = notFinishedApp[0].pTypeId;
          this.profileID = notFinishedApp[0].profileId;
          await this.fetchData();
          const currentApp = this.getCurrentApp();
          this.financialSummary.appliedDiscount = currentApp.appliedDiscount || [];
          $('html, body').scrollTop(0);
          this.loadToSlideContainsApp(notFinishedApp[0]);
        }else {
          // get payment info
          this.toggleSlideUp();
        }

        await this.removeFreeApp(freeApp);
        this.loading = false;
      }else {
        // applied to waitlist
        // if(this.totalPercent == 100 && (this.app.isWaitList || false)) {
        //   // finished registration, remove related item in cart
        //   if (this.cartItems.length) {
        //     this.cartItems = this.cartItems.filter((cartItem: any) => !(
        //       cartItem.eventID == this.app.eventID &&
        //       cartItem.profile.profileid == this.app.profileID &&
        //       cartItem.pType.participantTypeID == this.app.pTypeID
        //     ));
        //     ApiHelper.updateCartItems({ cartItems: this.cartItems });
        //     await ApiHelper.updateProfileStats();
        //   }

        //   this.$router.push({ name: "Registration" });
        //   return;
        // }else {
          // not set forcePayment
          const currentApp = this.saveCurrentAppState();
          if(currentApp) {
            currentApp.done = this.totalPercent == 100 ? true : false;
            // currentApp.appliedDiscount = this.financialSummary.appliedDiscount || [];
          }
          // update participantId for applications
          this.applications = this.applications.map(item => {
            const inCart = this.cartItems.find(cartItem =>
              cartItem.eventID == item.eventId &&
              cartItem.pType.participantTypeID == item.pTypeId &&
              cartItem.profile.profileid == item.profileId
            );
            if(inCart) {
              item.participantId = inCart.participantID || 0;
            }
            return item;
          });

          if(this.finishedApps() || (this.isEndApp() && this.haveDoneApps())) {
            // show pay & complete modal with option "pay later"
            this.toggleSlideUp();
          }else {
            // load next application if this is not end app
            if(!this.isEndApp()) {
              await this.loadNextApp();
              this.loadToSlideContainsApp();
            } else {
              if(this.applications.length == 1) {
                // go to registration page
                this.$router.push({ name: "Registration" });
                return;
              }

              let findIncompleteIndex = 0;
              let findIncompleteApp = null;
              this.applications.map((item, index) => {
                if (!item.done) {
                  findIncompleteApp = item;
                  findIncompleteIndex = index;
                }
              });
              if (findIncompleteApp) {
                await this.loadApp(findIncompleteIndex);
                this.loadToSlideContainsApp(findIncompleteApp);
              }

              // go to registration page
              // this.$router.push({ name: "Registration" });
            }
          }

          await this.removeFreeApp(freeApp);
          this.loading = false;

          return;
        // }
      }
    }

    return saveParticipantResult;
  }

  resetParticipantInfo() {
    this.participantID = 0;
    this.eventID = 0;
    this.pTypeID = 0;
    this.profileID = 0;
    this.registrationStep = 0;
    this.allowEditApp = false;
    this.updateAppOnly = 0;
    this.birthday = "";
    this.siderbar = [];
    this.camStoreFund = {};
    this.roommate = [];
    this.addOns = [];
    this.dynamicAdditionalCosts = [];
    this.addOnsTotal = 0;
    this.addOnsTotalFormatted = "$0.00";
    this.addOnsTotalMonthly = 0;
    this.addOnsTotalMonthlyFormatted = "$0.00";
    this.selectedAddOns = [];

    // reset financial summary
    this.financialSummary.discountCode = "";
    this.financialSummary.appliedDiscount = [];
    this.financialSummary.availableDiscounts = [];
  }

  async getUserData(form, item: any) {
    const jsonObj: any = [];
    const data = item.formRenderInstance.userData;

    const formContent = JSON.parse(item.appModule_json);

    const fileFields = formContent.filter(
      (content: any) => content.type === "file"
    );
    const formData: any = {};

    for (const fileField of fileFields) {
      const existData = {
        files: form.find(`input[name="${fileField.name}"]`).attr('files'),
        filePath: form.find(`input[name="${fileField.name}"]`).attr('file-path'),
        fileName: form.find(`input[name="${fileField.name}"]`).attr('file-name')
      };
      // set to existing value
      formData[fileField.name] = existData;
    }
    const formArray: any = item.formRenderInstance.userData;
    const formControls: any[] = [];
    for(const item of formArray) {
      let tmp: any = item;
      if (tmp.type === "file") {
        const foundControl = formData[item.name] || {};
        tmp.files = foundControl.files || undefined;
      }
      if (tmp.type === "signature") {
        const signatureData = $('canvas#'+item.name).attr('data-signature') || '';
        const signatureNew = $('canvas#'+item.name).attr('data-new') || '';
        if (signatureNew === '1') {
          const result = await ApiHelper.apiPost('/uploadFileFromBase64', {
            uuid: ApiHelper.getUuid(),
            domain: ApiHelper.getDomain(),
            base64: signatureData,
            group: "profiles/" + this.profileID + '/signatures',
            id: this.profileID,
            data: {
              fileName: ''
            }
          });
          if (result.status === 1) {
            tmp.signature = result.data.url;
            $('canvas#'+item.name).attr('data-new', '');
          } else {
            tmp.signature = signatureData;
          }
        } else {
          tmp.signature = signatureData;
        }

      }
      if (tmp.type === "dob") {
        const dateContainer = $('#date-'+item.name);
        const selDay = dateContainer.find('.sel-day').val();
        const selMonth = dateContainer.find('.sel-month').val();
        const selYear = dateContainer.find('.sel-year').val();
        const date = moment(selYear + '-' + selMonth + '-' + selDay);
        if (date.isValid()) {
          tmp = {...tmp, userData: [date.format('YYYY-MM-DD')]};
        }
      }
      formControls.push(tmp);
    }

    form.find(".form-group:not(.answer):not(.blank)").each(function (i, val) {
      const formItem: any = $(val);
      var item: any = {};
      item ["label"] = (formItem.find("label").html() || '').split("<span")[0];

      if (formItem.hasClass("formbuilder-radio-group")) {
        item ["field"] = formItem.find("input[type=radio]:checked").attr("name");
        item ["value"] = formItem.find("input[type=radio]:checked").val();
      }
      else if (formItem.hasClass("formbuilder-checkbox-group")) {
        item ["field"] = formItem.find("input").attr("name").replace("[]", "");
        const theField = data.find((f: any) => f.name == item.field);
        theField.values.map((checkbox: any, key: number) => {
          const isChecked = $('#' + item.field + '-' + key).is(':checked') ;
          checkbox.selected = isChecked;
        });
        if(theField) {
          item.userData = theField.userData;
          item.values = theField.values;
        }
      }
      else if (formItem.hasClass("formbuilder-textarea")) {
        item ["field"] = formItem.find("textarea").attr("name");
        item ["value"] = formItem.find("textarea").val();
      }
      else if (formItem.hasClass("formbuilder-select")) {
        item ["field"] = formItem.find("select").attr("name");
        item ["value"] = formItem.find("option:selected").val();
      }
      else {
        item ["field"] = formItem.find("input").attr("name");
        item ["value"] = formItem.find("input").val();
      }

      jsonObj.push(item);
    });

    const valid = this.validateForm();
    return {
      valid: valid,
      // json: data
      json: formControls
    }
  }

  validateStepForm(form) {
    let valid = true;
    form.find(".rendered-form .border-danger, .rendered-form .text-danger").removeClass("border-danger text-danger");
    form.find("input[type=radio][required]").each(function (index, control) {
      var _input = $(control);
      if (_input.attr('type') == 'radio') {
        var radioName = _input.attr('name');
        if ($('input[type=radio][name="' + radioName + '"]:checked').length == 0 && $('input[type=radio][name="' + radioName + '[]"]:checked').length == 0) {
          $(control).addClass("border-danger");
          $(control).parents('.form-group').addClass("text-danger");
          valid = false;
        } else {
          if ($('input[type=radio][name="' + radioName + '"]:checked').val() == '') {
            $(control).addClass("border-danger");
            $(control).parents('.form-group').addClass("text-danger");
            valid = false;
          }
        }
      }
    });
    form.find("input[type=text][required], input[type=email][required], input[type=date][required]").each(function (index, control) {
      if ($(control).val() == "") {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find("input[type=email]").each(function (index, control) {
      if ($(control).val() != "" && !ApiHelper.validateEmail($(control).val())) {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find("textarea[required]").each(function (index, control) {
      if ($(control).val() == "") {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find(".formbuilder-radio-group").each(function (index, control) {
      if ($(control).find("input[type=radio][required]").length > 0 && $(control).find("input[type=radio][required]:checked").length == 0) {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find("select[required]").each(function (index, control) {
      if ($(control).val() == 0 || $(control).val() == "") {
        $(control).addClass("border-danger");
        $(control).parent().addClass("text-danger");
        valid = false;
      }
    });
    return valid;
  }

  validateForm() {
    let valid = true;
    const form = $("#formBuilder");
    /*
    $(".rendered-form .border-danger, .rendered-form .text-danger").removeClass("border-danger text-danger");
    form.find("input[type=text][required]").each(function (i, val) {
      const formItem: any = $(val);
      if (formItem.val() == "") {
        formItem.addClass("border-danger");
        formItem.parent().addClass("text-danger");
        valid = false;
        return false;
      }
    });
    form.find("textarea[required]").each(function (i, val) {
      const formItem: any = $(val);
      if (formItem.val() == "") {
        formItem.addClass("border-danger");
        formItem.parent().addClass("text-danger");
        valid = false;
        return false;
      }
    });
    form.find("input[type=date][required]").each(function (i, val) {
      const formItem: any = $(val);
      if (formItem.val() == "") {
        formItem.addClass("border-danger");
        formItem.parent().addClass("text-danger");
        valid = false;
        return false;
      }
    });
    form.find(".formbuilder-radio-group").each(function (i, val) {
      const formItem: any = $(val);
      if (formItem.find("input[type=radio][required]").length > 0 && formItem.find("input[type=radio][required]:checked").length == 0) {
        const radio = formItem.find("input[type=radio][required]");
        radio.addClass("border-danger");
        radio.closest(".formbuilder-radio-group").addClass("text-danger");
        valid = false;
        return false;
      }
    });
    form.find(".formbuilder-checkbox-group").each(function (i, val) {
      const formItem: any = $(val);
      if (formItem.find("input[type=checkbox][required]").length > 0 && formItem.find("input[type=checkbox][required]:checked").length == 0) {
        const radio = formItem.find("input[type=checkbox][required]");
        radio.addClass("border-danger");
        radio.closest(".formbuilder-checkbox-group").addClass("text-danger");
        valid = false;
        return false;
      }
    });
    form.find("select[required]").each(function (i, val) {
      const formItem: any = $(val);
      if (formItem.val() == 0) {
        formItem.addClass("border-danger");
        formItem.parent().addClass("text-danger");
        valid = false;
        return false;
      }
    });
*/
    form.find(".rendered-form .border-danger, .rendered-form .text-danger").removeClass("border-danger text-danger");
    form.find("input[type=radio][required]").each(function (index, control) {
    var _input = $(control);
    if (_input.attr('type') == 'radio') {
      var radioName = _input.attr('name');
      if ($('input[type=radio][name="' + radioName + '"]:checked').length == 0 && $('input[type=radio][name="' + radioName + '[]"]:checked').length == 0) {
      $(control).addClass("border-danger");
      $(control).parents('.form-group').addClass("text-danger");
      valid = false;
      } else {
      if ($('input[type=radio][name="' + radioName + '"]:checked').val() == '') {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
      }
    }
    });
    form.find("input[type=text][required], input[type=email][required], input[type=date][required]").each(function (index, control) {
    if ($(control).val() == "") {
      $(control).addClass("border-danger");
      $(control).parents('.form-group').addClass("text-danger");
      valid = false;
    }
    });
    form.find("input[type=email]").each(function (index, control) {
      if ($(control).val() != "" && !ApiHelper.validateEmail($(control).val())) {
        $(control).addClass("border-danger");
        $(control).parents('.form-group').addClass("text-danger");
        valid = false;
      }
    });
    form.find("textarea[required]").each(function (index, control) {
    if ($(control).val() == "") {
      $(control).addClass("border-danger");
      $(control).parents('.form-group').addClass("text-danger");
      valid = false;
    }
    });
    form.find(".formbuilder-radio-group").each(function (index, control) {
    if ($(control).find("input[type=radio][required]").length > 0 && $(control).find("input[type=radio][required]:checked").length == 0) {
      $(control).addClass("border-danger");
      $(control).parents('.form-group').addClass("text-danger");
      valid = false;
    }
    });
    form.find("select[required]").each(function (index, control) {
      if ($(control).val() == 0 || $(control).val() == "") {
        $(control).addClass("border-danger");
        $(control).parent().addClass("text-danger");
        valid = false;
      }
    });
    return valid;
  }

  async saveParticipant() {
    const pRegistrationStep = (this.totalPercent == 100 || this.questionsNeeded == 0) ? 1 : 0;
    // just send totalFunds if system module "camp_store" is enable in app
    let totalFunds = 0;
    if(this.camStoreFund && (this.camStoreFund.moduleId || 0) > 0) {
      totalFunds = isNaN(parseFloat(this.totalFunds)) ? 0 : parseFloat(this.totalFunds);
    }

    if(!this.$validator.errors.has("emailInput") &&
      this.app.pEmail != "" &&
      this.app.pPrevEmail != "" &&
      this.app.pEmail != this.app.pPrevEmail) {
      this.loading = false;
      // user confirm wants to change email
      const confirm = await this.$swal.fire({
        // icon: "warning",
        text: `Are you sure you want to change email to ${this.app.pEmail}?`,
        confirmButtonText: "Confirm",
        showCloseButton: true,
        customClass: {
          container: "swal2-container-custom",
          confirmButton: "btn button SecondaryColor FontColor"
        }
      });
      if(!confirm.isConfirmed) {
        return false;
      }
    }

    let ret = false;
    this.loading = true;
    const requestObject: any = {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      eventID: this.app.eventID,
      pTypeID: this.app.pTypeID,
      profileID: this.app.profileID,
      appID: this.app.appID,
      steps: this.app.steps,
      participantJson: JSON.stringify(this.app.participantJSON),
      participantCost: this.app.participantCost,
      pRegistrationStep: pRegistrationStep,
      participantID: this.app.participantID,
      updateAppOnly: this.updateAppOnly,
      percentComplete: {
        questionsNeeded: this.questionsNeeded,
        questionsDone: this.questionsDone,
        percentComplete: this.totalPercent
      },
      addOns: {
        selectedAddOns: this.selectedAddOns,
        addOnsTotal: this.addOnsTotal,
        addOnsTotalFormatted: this.addOnsTotalFormatted,
        addOnsTotalMonthly: this.addOnsTotalMonthly,
        addOnsTotalMonthlyFormatted: this.addOnsTotalMonthlyFormatted
      },
      totalFunds,
      acceptInviteCode: this.inviteData.code || "",
      familyId: this.$loggedUser.familyId,
      isWaitList: this.app.isWaitList || false,
      eventName: this.app.eventName || "",
      isFreeRegistration: this.isFreeRegistration ? 1 : 0
    };

    // send profile details info if application has this module
    const profileDetailModule = this.siderbar.find((module: any) => module.stepkeyword == "profile_details");
    if (profileDetailModule) {
      const [birthdayValid, pDetailsValid] = await Promise.all([
        this.$refs.birthdaySelect[0].$validator.validateAll(),
        this.$validator.validateAll()
      ]);

      requestObject.profileDetails = {
        fname:  !this.$validator.errors.has("fNameInput") ? this.app.fName : undefined,
        lname: !this.$validator.errors.has("lNameInput") ? this.app.lName : undefined,
        email: !this.$validator.errors.has("emailInput") ? this.app.pEmail : undefined,
        dob: birthdayValid ? this.birthday : undefined,
        phone: !this.$validator.errors.has("phoneInput") ? this.app.pPhone : undefined,
        gender: !this.$validator.errors.has("genderInput") ? this.app.pGender : undefined,
        address: !this.$validator.errors.has("lAddressInput") ? this.app.pAddress : undefined,
        city: this.app.city,
        state: this.app.state,
        zip: this.app.zip
      }
    }

    if(this.financialSummary.appliedDiscount.length) {
      const discountHasCode = this.financialSummary.appliedDiscount.filter(item => item.discountCode != "");
      if(discountHasCode.length) {
        requestObject.appliedDiscount = discountHasCode;
      }
    }

    // if registering with a group, send groupId info
    const inCart = (this.cartItems || []).find(
      item => 
        item.eventID == this.app.eventID && 
        item.pType.participantTypeID == this.app.pTypeID && 
        item.profile.profileid == this.app.profileID
    );
    const groupId = inCart?.groupId || 0;
    requestObject.groupId = groupId;

    const response = await axios.post(`${dataURL}/saveParticipant`, requestObject);

    if(response.data.status == 1) {
      if(pRegistrationStep == 1) {
        await ApiHelper.updateProfileStats();
      }

      if (profileDetailModule && requestObject.profileDetails.fname && requestObject.profileDetails.lname) {
        if (this.$loggedUser.id == this.app.profileID) {
          const pageHeader: any = this.$refs.pageHeader;
          if (pageHeader && pageHeader.updateProfileName) {
            pageHeader.updateProfileName(requestObject.profileDetails.fname, requestObject.profileDetails.lname);
          }
        }
      }

      // update total registrations
      const resTotalCnt = await axios.post(`${dataURL}/getRegistrationsTotalCnt`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        profileID: this.$loggedUser.id
      });
      if(resTotalCnt.data.status == 1) {
        this.$loggedUser.stats.TotalEvents = resTotalCnt.data.data.totalCnt?.totalCount || 0;
        ApiHelper.saveLoggedUser(this.$loggedUser);
      }

      // update participantID to cart
      for(const item of this.cartItems) {
        if(item.eventID == this.app.eventID && item.pType.participantTypeID == this.app.pTypeID && item.profile.profileid == this.app.profileID) {
          item.participantID = response.data.data?.participantID || 0;
          item.addOns = this.selectedAddOns;
          item.totalFunds = totalFunds;
          item.pRegistrationStep = pRegistrationStep;
        }
      }

      // update participantId in applied discounts of preview financial summary
      for(const item of (this.financialSummary.appliedDiscount || [])) {
        item.appliedFor = [response.data.data?.participantID];
      }

      // const getPaymentInfo = true;
      // const saveCart = true;
      await this.updateCart(true, true);
      // this.reloadApp();

      // update current status of roommate requests
      this.registrationStep = pRegistrationStep;
      this.app.participantID = response.data.data?.participantID || 0;
      const saveRoommatesResult = response.data.data?.saveRoommatesResult || {};
      if((saveRoommatesResult.linkDormId || 0) > 0 && (saveRoommatesResult.status || 0) == 1) {
        const inRoomMate = this.roommate.find(item => 
          item.requestId == saveRoommatesResult.linkDormId
        );
        if(inRoomMate) {
          // mark as accepted
          inRoomMate.link_active = 1;
        }
      }
      ret = true;
    }else {
      const errorCode = response.data.data?.errorCode || "";
      const message = response.data.message || "";
      if(errorCode == "email_existed" && message != "") {
        this.loading = false;
        await this.$swal.fire({
          // icon: "warning",
          // text: `Email "${this.app.pEmail}" already exists. Please select "forgot password" to reset your password or sign up with a new email address.`,
          text: message,
          confirmButtonText: "OK",
          showCloseButton: true,
          customClass: {
            container: "swal2-container-custom",
            confirmButton: "btn button SecondaryColor FontColor"
          }
        });
        if($("#emailInput").length) {
          $('html, body').animate({
            scrollTop: $("#emailInput").offset().top - 70
          }, 500, function() {
            setTimeout(() => {
              $("#emailInput").focus();
            }, 300);
          });
        }
      }
      ret = false;
    }
    return ret;
  }

  getPercentStepComplete(formRenderInstance: any) {
    const data = formRenderInstance.userData || [];
    let ret = {
      questionsNeeded: 0,
      questionsDone: 0,
      percentComplete: 0
    };
    if(data.length) {
      const requires = data.filter((item: any) => item.required);
      ret.questionsNeeded = requires.length;
      for(const item of requires) {

        if(["text", "textarea", "date"].includes(item.type) && typeof item.userData[0] == "string" && item.userData[0] != "") {
          const subType = item.subtype || "";
          if (subType == "email") {
            const inputValue = item.userData[0] || "";
            if (ApiHelper.validateEmail(inputValue)) {
              ret.questionsDone += 1;
            }
          } else {
            ret.questionsDone += 1;
          }
        }
        if(["dob"].includes(item.type)) {
          const dateContainer = $('#date-' + item.name);
          if (dateContainer.find('.sel-day').val() && dateContainer.find('.sel-month').val() && dateContainer.find('.sel-year').val()) {
            ret.questionsDone += 1;
          }
        }
        if(["select", "checkbox-group", "radio-group"].includes(item.type) && typeof item.userData != "undefined") {
          ret.questionsDone += 1;
        }
        if(["signature"].includes(item.type)) {
          const signatureCanvas = $('#'+item.name);
          const strSignature = signatureCanvas.attr('data-signature');
          if (strSignature) {
            ret.questionsDone += 1;
          }
        }
      }
      if(ret.questionsNeeded > 0) {
        ret.percentComplete = parseInt(((ret.questionsDone/ret.questionsNeeded)*100).toFixed(2));
      }
    }
    // case form have no required fields
    if(ret.questionsNeeded == 0) {
      ret.percentComplete = 100;
    }

    return ret;
  }

  // getPercentComplete() {
  //   const data = this.formRenderInstance.userData;
  //   this.formUserData = data;
  //   let ret = {
  //     questionsNeeded: 0,
  //     questionsDone: 0,
  //     percentComplete: 0
  //   };
  //   if(data.length) {
  //     const requires = data.filter((item: any) => item.required);
  //     ret.questionsNeeded = requires.length;
  //     for(const item of requires) {
  //       if(["text", "date", "textarea"].includes(item.type) && typeof item.userData[0] == "string" && item.userData[0] != "") {
  //         ret.questionsDone += 1;
  //       }
  //       if(["select", "checkbox-group", "radio-group"].includes(item.type) && typeof item.userData != "undefined") {
  //         ret.questionsDone += 1;
  //       }
  //     }
  //     if(ret.questionsNeeded > 0) {
  //       ret.percentComplete = parseInt(((ret.questionsDone/ret.questionsNeeded)*100).toFixed(2));
  //     }
  //   }
  //   // case form have no required fields
  //   if(ret.questionsNeeded == 0) {
  //     ret.percentComplete = 100;
  //   }

  //   this.percentComplete = ret;

  //   return ret;
  // }

  // onFormChange() {
  //   const $this = this;
  //   $(`#formBuilder input[type=text], #formBuilder input[type=checkbox],
  //     #formBuilder input[type=date], #formBuilder input[type=radio],
  //     #formBuilder select, #formBuilder textarea`).change(function() {
  //     $this.getPercentComplete();
  //     $this.getTotalPercent();
  //     $this.validateForm();
  //   });
  // }
  onFormChange(sectionId) {
    const $this = this;
    $(`#${sectionId} input[type=text], #${sectionId} input[type=checkbox],
      #${sectionId} input[type=date], #${sectionId} input[type=radio], #${sectionId} input[type=email],
      #${sectionId} select, #${sectionId} textarea`).on('focusout', function() {
      $this.getTotalPercent();
      $this.validateForm();
    });
  }

    toggleSelectedAddOns(cost, isPriorEvent = 0) {
        let newTotal: any = 0;
        if(isPriorEvent == 1) return;
      
        if(this.inSelectedAddOns(cost)) {
          this.selectedAddOns = this.selectedAddOns.filter((item: any) => item.addonServiceId != cost.addonServiceId);
        }else {
          if(cost.cost > 0) {
            this.selectedAddOns.push(cost);
          }
        }

        // calculate for total addOns, and total for paying monthly
        this.addOnsTotal = 0;
        this.addOnsTotalMonthly = 0;
        this.addOnsTotalFormatted = ApiHelper.dollarFormat(this.addOnsTotal);
        this.addOnsTotalMonthlyFormatted = ApiHelper.dollarFormat(this.addOnsTotalMonthly);
        if(this.selectedAddOns.length) {
            for(const cost of this.selectedAddOns) {
                this.addOnsTotal += parseFloat(cost.cost || 0);
                this.addOnsTotalMonthly += cost.costPaymentInfo?.recurringAmount || 0;
            }
            this.addOnsTotal = parseFloat(this.addOnsTotal.toFixed(2));
            this.addOnsTotalFormatted = ApiHelper.dollarFormat(this.addOnsTotal);
            this.addOnsTotalMonthly = parseFloat(this.addOnsTotalMonthly.toFixed(2));
            this.addOnsTotalMonthlyFormatted = ApiHelper.dollarFormat(this.addOnsTotalMonthly);
        }

        // setTimeout(() => {
        //   let index = 1;
        //   $(".participants-slide-item").each(() => {
        //     index++;
        //     if (typeof $(".participants-slide-item:nth-of-type("+index+")").find(".p-event-totalcost").html() != "undefined"){
        //       newTotal = parseFloat(newTotal) + parseFloat(($(".participants-slide-item:nth-of-type("+index+")").find(".p-event-totalcost").html()).replace('$', '').replace(',', '')); 
        //       /* newTotal = parseFloat(newTotal) + parseFloat(($(this).find(".p-event-totalcost").html()).replace('$', '').replace(',', '')); */
        //     } 
        //   });
        //   $(".application_total_cost .total_cost").html("$" + newTotal.toFixed(2));
        // },100);       
    }

    inSelectedAddOns(cost) {
        const inList = this.selectedAddOns.find((item: any) => item.addonServiceId == cost.addonServiceId);
        if(inList) {
            return true;
        }

        return false;
    }

    async storeFundTxtBlur() {
        const valid = await this.$validator.validateAll();
        if(!valid && this.$validator.errors.items.find((item: any) => item.field == "totalFunds")) {
          this.totalFunds = "";
        }else if(this.totalFunds != "" && parseFloat(this.totalFunds) < 0) {
          // pass validate but < 0
          this.totalFunds = "";
        }
    }

  // inviteData = {
  //   isProcessing: false,
  //   code: '',
  //   email: '',
  //   valid: false,
  //   verified: false
  // }

  async validateInviteForm() {
    if (ApiHelper.validateEmail(this.inviteData.email) && this.inviteData.firstName) {
      this.inviteData.valid = true;
    } else {
      this.inviteData.valid = false;
    }

  }
  async sendInvitation() {
    this.inviteData.emailError = '';
    this.inviteData.firstNameError = '';
    this.inviteData.lastNameError = '';
    if(this.roomateRequestIsDisabled()) {
      return;
    }
    if (this.inviteData.firstName == '') {
      this.inviteData.firstNameError = 'First Name is required';
      this.inviteData.valid = false;
    }
    if (this.inviteData.email == '') {
      this.inviteData.emailError = 'Email is required';
      this.inviteData.valid = false;
    } else if(!ApiHelper.validateEmail(this.inviteData.email)){
      this.inviteData.emailError = 'Email is invalid';
      this.inviteData.valid = false;
    }

    if (!this.inviteData.valid) {
      return;
    }
    this.inviteData.isProcessing = true;
    const loggedUser = ApiHelper.getLoggedUser();

    const result = await ApiHelper.apiPost('/saveRoommateInvite', {
      uuid: loggedUser.entityUUID,
      profileID: this.app.profileID,
      participantID: this.app.participantID,
      pTypeID: this.app.pTypeID,
      eventID: this.app.eventID,
      code: this.app.inviteCode,
      email: this.inviteData.email.toLowerCase(),
      firstName: this.inviteData.firstName,
      lastName: this.inviteData.lastName
    });
    this.inviteData.isProcessing = false;
    if (result.status == 1) {
      // ApiHelper.showSuccessMessage(`
      //   An invitation email with invite code <strong>${this.app.inviteCode}</strong> was sent to ${this.inviteData.email}
      // `);
      await this.$swal.fire({
        // icon: "success",
        title: "Success",
        html: `An invitation email with invite code <strong>${this.app.inviteCode}</strong> was sent to ${this.inviteData.email}`,
        customClass: {
          container: "swal2-container-custom",
          confirmButton: "btn button SecondaryColor FontColor"
        }
      });
      this.inviteData.email = '';
      this.inviteData.firstName = '';
      this.inviteData.lastName = '';
      this.inviteData.valid = false;
      // refresh invite code for next request
      this.app.inviteCode = ApiHelper.getRandomInt(100000, 999999);

      // render new raw
      const pInfo = result.data;
      if(pInfo.participantId) {
        this.roommate.push({
          p_fname: pInfo.pfname || "",
          p_lname: pInfo.plname || "",
          p_city: pInfo.pcity || "",
          p_state: pInfo.pstate || "",
          age: pInfo.age || ""
        });
      }
    } else {
      const errorCode = result.errorCode;
      let message = result.message || "";
      if(errorCode == "profiles_not_found") {
        message = "Profile you want to invite not found. Please check your inputted information";
      }
      ApiHelper.showErrorMessage(message, 'Oops');
    }
  }

  dynamicCostBlur(cost) {
    if(isNaN(cost.cost) || cost.cost < 0 || cost.cost == "") {
      cost.cost = 0;
      cost.costFormatted = "$0.00";
      this.selectedAddOns = this.selectedAddOns.filter((item: any) => item.addonServiceId != cost.addonServiceId);
      this.toggleSelectedAddOns(cost);
    }else {
      cost.cost = parseFloat(cost.cost);
    }
  }

  async dynamicCostChange(cost) {
    clearTimeout(this.dynamicCostChangeTimeout);
    this.dynamicCostChangeTimeout = setTimeout(async () => {
      // if(isNaN(cost.cost) || cost.cost < 0 || cost.cost == "") {
      //   cost.cost = 0;
      // }

      // get payment info for this cost
      if(cost.cost > 0) {
        const response = await axios.post(`${dataURL}/getPaymentInfo`, {
          domain: ApiHelper.getDomain(),
          uuid: ApiHelper.getUuid(),
          familyID: this.$loggedUser.familyId,
          eventIDs: `${cost.eventId}`,
          total: parseFloat(cost.cost),
          hasDeposit: false,
          addonServiceId: cost.addonServiceId,
          isService: true
        });
        if(response.data.status == 1) {
          const paymentInfo = response.data.data.paymentInfo;
          if (paymentInfo.stripePublishableKey) {
            delete paymentInfo.stripePublishableKey;
          }
          cost.costPaymentInfo = paymentInfo;
        }
        cost.costFormatted = ApiHelper.dollarFormat(cost.cost);
      }else {
        cost.costPaymentInfo.recurringAmount = 0;
      }

      // cost.cost = parseFloat(cost.cost);
      // cost.costFormatted = ApiHelper.dollarFormat(cost.cost);
      this.selectedAddOns = this.selectedAddOns.filter((item: any) => item.addonServiceId != cost.addonServiceId);
      this.toggleSelectedAddOns(cost);
    }, 300);
  }

  async getTotalPercent() {
    // final total percent
    let totalPercent = 0;
    try {
      let questionsNeeded = 0;
      let questionsDone = 0;
      let percentComplete = 0;

      const totalModules = this.siderbar.filter((item: any) => (item.isSystem || false) == false || item.stepkeyword == "profile_details").length;
      if (totalModules == 0) {
        this.totalPercent = 100;
        this.questionsNeeded = 0;
        this.questionsDone = 0;
        return this.totalPercent;
      }

      // specify percent for each module
      const modulePercent = parseFloat((100 / totalModules).toFixed(2));

      // increase or decrease % for first module to make sure total percent of modules is 100%
      let firstModulePercent = modulePercent;
      let firstModuleKey = this.siderbar.filter((module: any) => (module.isSystem || false) == false)[0]?.stepkeyword || "";
      const totalModulePercent = modulePercent * totalModules;
      if(100 > totalModulePercent) {
        firstModulePercent += parseFloat((100 - totalModulePercent).toFixed(2));
      }else if(100 < totalModulePercent) {
        firstModulePercent -= parseFloat((totalModulePercent - 100).toFixed(2));
      }
      firstModulePercent = parseFloat(firstModulePercent.toFixed(2));

      // specify percent stats for profile details
      let pPercentComplete: any = {
        questionsNeeded: 0,
        questionsDone: 0,
        percentComplete: 0
      };
      const profileDetailModule = this.siderbar.find((module: any) => module.stepkeyword == "profile_details");
      if (profileDetailModule) {
        const pDetailsFields = ["fNameInput", "lNameInput", "genderInput", "phoneInput", "lAddressInput", "lCityInput", "lStateInput", "lZipInput"];
        pPercentComplete.questionsNeeded += pDetailsFields.length + 1; //1 for birthday field
        for(const id of pDetailsFields) {
          const fieldControl: any = $(`#${id}`);
          switch (id) {
            case "lZipInput":
            {
              const value = fieldControl.val() || "";
              if (value.length > 4) {
                pPercentComplete.questionsDone += 1;
              }
            }
              break;
            case "phoneInput":
              {
                const value = fieldControl.val() || "";
                if (value.length > 9) {
                  pPercentComplete.questionsDone += 1;
                }
              }
              break;
            default:
              if (fieldControl && (fieldControl.val() || "").trim() != "") {
                pPercentComplete.questionsDone += 1;
              }
              break;
          }
        }
        // const [birthdayValid] = await Promise.all([this.$refs.birthdaySelect[0].$validator.validateAll()]);
        const monthVal = $(`#monthInput-${this.birthdaySelectUID}`).val();
        const dayVal = $(`#dayInput-${this.birthdaySelectUID}`).val();
        const yearVal = $(`#yearInput-${this.birthdaySelectUID}`).val();
        const birthdayValid = monthVal != "" && dayVal != "" && yearVal != "" ? true : false;
        if(birthdayValid) {
          pPercentComplete.questionsDone += 1;
        }
      }

      const guardianModule = this.siderbar.find((module: any) => module.stepkeyword == "parent_guardian");
      if (guardianModule) {
        const minRequirement = guardianModule.minRequirement || 0;
        const required = guardianModule.required || false;
        if (required) {
          if (minRequirement) {
            pPercentComplete.questionsNeeded += 1;
          }
          if (minRequirement && this.members.filter((item) => item.isGuardian).length >= minRequirement) {
            pPercentComplete.questionsDone += 1;
          }
        }
      }
      if (profileDetailModule || guardianModule) {
        if(pPercentComplete.questionsNeeded > 0) {
          pPercentComplete.percentComplete = parseInt(((pPercentComplete.questionsDone/pPercentComplete.questionsNeeded)*100).toFixed(2));
        } else if(pPercentComplete.questionsNeeded == 0) {
          pPercentComplete.percentComplete = 100;
        }
      }


      // specify percent stats for each normal module
      for(const module of this.siderbar) {
        if(module.formRenderInstance && (module.isSystem || false) == false) {
          const percentComplete = {
            questionsNeeded: 0,
            questionsDone: 0,
            percentComplete: 0
          };
          const data = module.formRenderInstance.userData;
          if(data.length) {
            const fRequiredFields = data.filter((item: any) => item.required);
            percentComplete.questionsNeeded += fRequiredFields.length;
            for(const item of fRequiredFields) {
              if(["text", "textarea", "date"].includes(item.type) && typeof item.userData[0] == "string" && item.userData[0] != "") {
                const subType = item.subtype || "";
                if (subType == "email") {
                  const inputValue = item.userData[0] || "";
                  if (ApiHelper.validateEmail(inputValue)) {
                    percentComplete.questionsDone += 1;
                  }
                } else {
                  percentComplete.questionsDone += 1;
                }
              }
              if (item.type === "file") {
                try {
                  const files = JSON.parse(
                    $('#'+item.name)
                      .attr("files") || "[]"
                  );
                  if (files.length > 0) {
                    percentComplete.questionsDone += 1;
                  }
                } catch (e) {
                  // nothing
                }
              }
              if(["dob"].includes(item.type)) {
                const dateContainer = $('#date-' + item.name);
                if (dateContainer.find('.sel-day').val() && dateContainer.find('.sel-month').val() && dateContainer.find('.sel-year').val()) {
                  percentComplete.questionsDone += 1;
                }
              }
              if(["select", "checkbox-group", "radio-group"].includes(item.type) && typeof item.userData != "undefined") {
                percentComplete.questionsDone += 1;
              }
              if(["signature"].includes(item.type)) {
                const signatureCanvas = $('#'+item.name);
                const strSignature = signatureCanvas.attr('data-signature');
                if (strSignature) {
                  percentComplete.questionsDone += 1;
                }
              }
            }
            // percent for this module
            if(percentComplete.questionsNeeded > 0) {
              percentComplete.percentComplete = parseInt(((percentComplete.questionsDone/percentComplete.questionsNeeded)*100).toFixed(2));
            }else if(percentComplete.questionsNeeded == 0) {
              percentComplete.percentComplete = 100;
            }
          }
          module.percentComplete = percentComplete;
        }
      }

      for(const module of this.siderbar) {
        if((module.isSystem || false) == false) {
          const percentComplete = module.percentComplete;
          const finalModulePercent = (module.stepkeyword == firstModuleKey ? firstModulePercent : modulePercent);
          totalPercent += (percentComplete.percentComplete * finalModulePercent) / 100;
          questionsNeeded += percentComplete.questionsNeeded;
          questionsDone += percentComplete.questionsDone;
        }
      }
      // include percent that calcualted for profile details
      if(profileDetailModule || guardianModule) {
        const finalModulePercent = (profileDetailModule.stepkeyword == firstModuleKey ? firstModulePercent : modulePercent);
        totalPercent += (pPercentComplete.percentComplete * finalModulePercent) / 100;
        questionsNeeded += pPercentComplete.questionsNeeded;
        questionsDone += pPercentComplete.questionsDone;
      }

      this.totalPercent = parseInt(totalPercent.toFixed(2));
      this.questionsNeeded = questionsNeeded;
      this.questionsDone = questionsDone;

      this.makesvg(totalPercent,parseInt($('.circlechart').attr("data-tab")),parseInt($('.circlechart').attr("data-total")));
    } catch (err) {
      // do nothing
    }

    $('.progress_bar_percent').text(`${totalPercent}%`);
    $('#progress_bar_ratio').css('width', `${totalPercent}%`);

    return totalPercent;
  }

  dateNotValid(uid: string) {
    const errors = this.$validator.errors.items;
    if(errors.find((item: any) => item.field == `monthInput-${uid}`) ||
      errors.find((item: any) => item.field == `dayInput-${uid}`) ||
      errors.find((item: any) => item.field == `yearInput-${uid}`)
    ) {
      return true;
    }

    return false;
  }

  applyCustomWidth(renderedForm: any) {
    // apply custom width for each module
    renderedForm.find("[class*='custom-w-']").each(function(i, obj) {
      const classList = obj.className.split(" ");
      const customW = classList.find(item => item.indexOf("custom-w-") != -1);
      const customWClass = customW ? customW.replace("custom-w-", "") : "";
      if(customWClass != "") {
        const type = $(obj).prop("type");
        if(type == "checkbox" || type == "radio") {
          $(obj).closest(".form-group").addClass(customWClass);
        }else {
          $(obj).parent().addClass(customWClass);
        }
      }
    });

    //   $(".rendered-module-forms").each(function(i, obj) {
    //     // checkbox group
    //     $(obj).find(".checkbox-group input[type=checkbox][class*='custom-w-']").each(function(cbIndex, cb) {
    //       let customClass= "";
    //       if($(cb).hasClass("custom-w-25")) {
    //         customClass = "w-25";
    //       }else if($(cb).hasClass("custom-w-33")) {
    //         customClass = "w-33";
    //       }else if($(cb).hasClass("custom-w-50")) {
    //         customClass = "w-50";
    //       }else if($(cb).hasClass("custom-w-66")) {
    //         customClass = "w-66";
    //       }else if($(cb).hasClass("custom-w-75")) {
    //         customClass = "w-75";
    //       }else if($(cb).hasClass("custom-w-100")) {
    //         customClass = "w-100";
    //       }
    //       $(cb).closest(".checkbox-group").addClass(customClass);
    //     });

    //     // radio group
    //     $(obj).find(".radio-group input[type=radio][class*='custom-w-']").each(function(cbIndex, cb) {
    //       let customClass= "";
    //       if($(cb).hasClass("custom-w-25")) {
    //         customClass = "w-25";
    //       }else if($(cb).hasClass("custom-w-33")) {
    //         customClass = "w-33";
    //       }else if($(cb).hasClass("custom-w-50")) {
    //         customClass = "w-50";
    //       }else if($(cb).hasClass("custom-w-66")) {
    //         customClass = "w-66";
    //       }else if($(cb).hasClass("custom-w-75")) {
    //         customClass = "w-75";
    //       }else if($(cb).hasClass("custom-w-100")) {
    //         customClass = "w-100";
    //       }
    //       $(cb).closest(".radio-group").addClass(customClass);
    //     });
    //   });
  }

  // upload file
  updateFilePreview(fileSelector: any) {
    const files = JSON.parse(fileSelector.attr('files') || '[]') || [];
    let previewHtml = '';
    for (let file of files) {
      const fileName = file.name || '';
      const filePath = file.path;
      const ext = ApiHelper.getFileExt(fileName).toLowerCase();
      if (ext == 'jpg' || ext == 'png' || ext == 'gif') {
        previewHtml += '<li><a href="' + filePath + '" target="_blank"><img src="' + filePath + '" alt="' + fileName + '" /></a> <a href="javascript:void(0)" class="delete-file" data-file="' + fileName + '">Delete</a></li>';
      } else {
        previewHtml += '<li><a href="' + filePath + '" target="_blank">' + fileName + '</a> <a href="javascript:void(0)"  class="delete-file" data-file="' + fileName + '">Delete</a></li>';
      }
    }
    if (fileSelector.parent().find('.file-preview').length > 0) {
      fileSelector.parent().find('.file-preview ul').html(previewHtml);
    } else {
      fileSelector.parent().append('<div class="file-preview mt-1"><ul>' + previewHtml + '</ul></div>');
    }
    this.getTotalPercent();
  }

  replaceSpecialCharacters(value: string) {
    return value.replace(/[^\w ]/g, '');
  }

  // showPayAndCompleteButton() {
  //   if(!this.forcePayment) return false;

  //   let notFinishedApp = this.applications.filter(
  //     (item: any) =>
  //       // not current app
  //       !(item.eventId == this.app.eventID && item.pTypeId == this.app.pTypeID && item.profileId == this.app.profileID) &&
  //       // not done
  //       (item.done || false) == false
  //   );
  //   if(notFinishedApp.length == 0) {
  //     return true;
  //   }
  //   return false;
  // }

  async buildApplications(pInCart: any = []) {
    this.applications = [];
    this.totalCost = 0;
    this.totalCostFormatted = ApiHelper.dollarFormat(this.totalCost);

    // check if seeing an app in cart, or a app not in cart
    const appInCart = this.cartItems.find(item =>
      this.app.eventID == item.eventID &&
      this.app.pTypeID == item.pType.participantTypeID &&
      this.app.profileID == item.profile.profileid
    );
    if(appInCart) {
      // seeing an app in cart
      for (const item of this.cartItems) {
        const profile = item.profile;
        const pType = item.pType;
        this.totalCost += pType.participantAmount || 0;
        const pInCartItem = pInCart.find(t => t.eventId == item.eventID && t.pTypeId == pType.participantTypeID && t.profileId == profile.profileid);
        const tsStartFormatted = pInCartItem?.tsStartFormatted || "";
        const tsEndFormatted = pInCartItem?.tsEndFormatted || "";
        
        this.applications.push({
          profileId: profile.profileid,
          firstName: profile.p_fname || "",
          lastName: profile.p_lname || "",
          logo: profile.p_logo || "",
          eventId: item.eventID,
          eventName: pType.eventName || "",
          pTypeId: pType.participantTypeID || 0,
          participantId: item.participantID || 0,
          participantTypeName: pType.participantTypeName || "",
          participantAmount: pType.participantAmount || 0,
          participantAmountFormatted: ApiHelper.dollarFormat(pType.participantAmount || 0),
          totalCost: pType.participantAmount || 0,
          totalCostFormatted: ApiHelper.dollarFormat(pType.participantAmount || 0),
          registrationStep: item.pRegistrationStep || 0,
          done: (item.pRegistrationStep || 0) > 0 ? true : false,
          tsStartFormatted,
          tsEndFormatted
        });
      }
      this.totalCost = parseFloat(this.totalCost.toFixed(2));
      this.totalCostFormatted = ApiHelper.dollarFormat(this.totalCost);
    }else {
      // seeing an app not in cart
      this.applications.push({
        profileId: this.app.profileID,
        firstName: this.app.fName,
        lastName: this.app.lName,
        eventId: this.app.eventID,
        eventName: this.app.eventName,
        pTypeId: this.app.pTypeID,
        participantId: this.app.participantID || 0,
        participantTypeName: this.app.pTypeName,
        participantAmount: this.app.participantCost,
        participantAmountFormatted: ApiHelper.dollarFormat(this.app.participantCost),
        totalCost: this.app.participantCost,
        totalCostFormatted: ApiHelper.dollarFormat(this.app.participantCost),
        registrationStep: this.registrationStep || 0,
        done: this.registrationStep > 0 ? true : false,
      });
      this.totalCost = parseFloat(this.app.participantCost.toFixed(2));
      this.totalCostFormatted = ApiHelper.dollarFormat(this.totalCost);
    }

    // get saved info of participants (addons, campstore funds)
    const tmpParticipants = this.applications.filter(item => item.participantId == 0);
    const participantIds = this.applications
      .filter(item => item.participantId > 0)
      .map(item => item.participantId);
    const response = await axios.post(`${dataURL}/unpaidRegistration`, {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      familyID: this.$loggedUser.familyId,
      tmpProfileIDs: tmpParticipants.map((item: any) => item.profileId),
      tmpEventIDs: tmpParticipants.map((item: any) => item.eventId),
      participantIds: participantIds.length ? participantIds.join(",") : "0",
      includeRegisteredStatus: 1
    });
    if (response.data.status == 1) {
      this.discountsHasCode = response.data.data.discountsHasCode || [];
      const unpaidRegistration = response.data.data.unpaidRegistration || [];
      if(unpaidRegistration.length) {
        this.applications = this.applications.map(item => {
          const relatedUnpaidItem = unpaidRegistration.find(t => t.participantID == item.participantId);
          if(relatedUnpaidItem) {
            item.pPaymentJson = JSON.parse(relatedUnpaidItem.participant_paymentJson || "{}");
            item.registrationStep = relatedUnpaidItem.pRegistrationStep;
            item.done = item.registrationStep > 0 ? true : false;
            item.totalFunds = relatedUnpaidItem.totalFunds || 0;
            item.selectedAddOns = relatedUnpaidItem.additionalCosts || [];
            // for(const cost of item.selectedAddOns) {
            //   item.totalCost += cost.cost || 0;
            // }
            // item.totalCost += item.totalFunds;
            item.participantAmount = relatedUnpaidItem.cost + relatedUnpaidItem.participantDiscountCost;
            item.participantAmount = parseFloat(item.participantAmount.toFixed(2));
            item.participantAmountFormatted = dollarFormat(item.participantAmount);

            item.totalCost = relatedUnpaidItem.totalCost + relatedUnpaidItem.participantDiscountCost;
            item.totalCost = parseFloat(item.totalCost.toFixed(2));
            item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);

            // if([0, 1].includes(item.registrationStep) && (relatedUnpaidItem.participantDiscountCost || 0) > 0) {
            //   // if applied a discount before
            //   item.participantAmount -= relatedUnpaidItem.participantDiscountCost;
            //   item.participantAmount = parseFloat(item.participantAmount.toFixed(2));
            //   item.totalCost -= relatedUnpaidItem.participantDiscountCost;
            //   item.totalCost = parseFloat(item.totalCost.toFixed(2));
            //   item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
            // }
          }

          return item;
        });
      }

      // append discount info for each registration
      const availableDiscounts = response.data.data.availableDiscounts || [];
      if(availableDiscounts.length) {
        this.applications = this.applications.map(item => {
          let discountAmount = 0;
          const discount = availableDiscounts.find(discount => discount.eventId == item.eventId && discount.participant_typeId == item.pTypeId);
          if(discount) {
            discountAmount = discount.discountAmount || 0;
          }
          item.discountAmount = discountAmount;
          item.discountAmountFormatted = ApiHelper.dollarFormat(item.discountAmount);
          item.discount = discount;

          return item;
        });
      }
    }
  }

  // async payAndComplete() {
  //   this.form.cardName.error = '';
  //   this.form.cardNumber.error = '';
  //   this.form.expMoth.error = '';
  //   this.form.expyear.error = '';
  //   this.form.cvc.error = '';
  //   this.form.zipcode.error = '';
  //   this.paymentErrMessage = '';
  //   const currentApp = this.saveCurrentAppState();
  //   if(currentApp) {
  //     currentApp.done = this.totalPercent == 100 ? true : false;
  //     currentApp.appliedDiscount = this.financialSummary.appliedDiscount || [];
  //   }

  //   // save participant
  //   const valid = await this.saveProgress();
  //   if(!valid) return;

  //   // update roommate status for current app
  //   if(currentApp) {
  //     currentApp.roommate = this.roommate;
  //     currentApp.registrationStep = this.registrationStep;
  //   }

  //   // update participantId for applications
  //   this.applications = this.applications.map(item => {
  //     const inCart = this.cartItems.find(cartItem =>
  //       cartItem.eventID == item.eventId &&
  //       cartItem.pType.participantTypeID == item.pTypeId &&
  //       cartItem.profile.profileid == item.profileId
  //     );
  //     if(inCart) {
  //       item.participantId = inCart.participantID || 0;
  //     }
  //     return item;
  //   });

  //   // load next application if any
  //   const notFinishedApp = this.applications.filter(
  //     (item: any) => (item.done || false) == false
  //   );
  //   if (notFinishedApp.length) {
  //     this.resetParticipantInfo();
  //     this.participantID = notFinishedApp[0].participantId || 0;
  //     this.eventID = notFinishedApp[0].eventId;
  //     this.pTypeID = notFinishedApp[0].pTypeId;
  //     this.profileID = notFinishedApp[0].profileId;
  //     await this.fetchData();
  //     const currentApp = this.getCurrentApp();
  //     this.financialSummary.appliedDiscount = currentApp.appliedDiscount || [];
  //     $('html, body').scrollTop(0);
  //   }else {
  //     // get payment info
  //     this.toggleSlideUp();
  //   }
  // }

  async toggleSlideUp() {
    this.cardInfoError = '';
    const slideup = $(".slideupContainer");
    if (slideup.hasClass("invisible")) {
      this.form.cardName.error = '';
      this.form.cardNumber.error = '';
      this.form.expMoth.error = '';
      this.form.expyear.error = '';
      this.form.cvc.error = '';
      this.form.zipcode.error = '';
      this.form.cardName.value = "";
      this.form.cardNumber.value = "";
      this.form.expMoth.value = "";
      this.form.expyear.value = "";
      this.form.cvc.value = "";
      this.form.zipcode.value = "";

      this.appliedDiscount = [];
      this.paymentErrMessage = "";
      $(".slide_container").height($(".slide_wrapper").outerHeight());
      var fullHeight = slideup.outerHeight();
      slideup.css("height", 0).removeClass("invisible").animate({ height: fullHeight }, 100, "linear");
      this.discountCode = "";
      this.availableDiscounts = [];

      // apply discount for selected items
      const applications = JSON.parse(JSON.stringify(this.applications.filter(item => (item.done || false))));
      let totalDiscountNoCode = 0;
      const appliedFor: any = [];
      for(const item of applications) {
        const discountAmount = item.discountAmount || 0;
        if(discountAmount > 0) {
          totalDiscountNoCode += discountAmount;
          item.participantAmount -= discountAmount;
          item.participantAmount = parseFloat(item.participantAmount.toFixed(2));
          item.participantAmountFormatted = ApiHelper.dollarFormat(item.participantAmount);
          item.totalCost -= discountAmount;
          item.totalCost = parseFloat(item.totalCost.toFixed(2));
          item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
          appliedFor.push(item.participantId);
        }
      }

      // save applied discount
      if(totalDiscountNoCode > 0) {
        this.appliedDiscount = [{
          discountId: 0,
          discountCode: "",
          discountAmount: parseFloat(totalDiscountNoCode.toFixed(2)),
          appliedFor
        }];
      }

      // check if applied some discounts in financial summary preview section
      for(const app of applications) {
        const discountHasCode = app.appliedDiscount.filter(item => item.discountCode != "");
        for (const discountItem of discountHasCode) {
          const relatedSelected = applications.filter((item: any) =>
            discountItem.appliedFor.includes(item.participantId)
          );
          for (const item of relatedSelected) {
            const amount =
              item.participantAmount - discountItem.discountAmount;
            // prevent if applying discount and make amount < 0
            if (amount >= 0) {
              item.participantAmount -= discountItem.discountAmount;
              item.participantAmount = parseFloat(item.participantAmount.toFixed(2));
              item.totalCost -= discountItem.discountAmount;
              item.totalCost = parseFloat(item.totalCost.toFixed(2));
              item.totalCostFormatted = ApiHelper.dollarFormat(
                item.totalCost
              );
              this.appliedDiscount.push({...discountItem});
            }
          }
        }
      }

      await this.getPaymentInfo(applications);
    } else {
      await this.showForcePaymentNote();
      slideup.animate({ height: 0 }, 100, "linear", function () {
        $(".slide_container").removeAttr("style");
        slideup.removeAttr("style");
        slideup.addClass("invisible");
      });
    }
  }

  saveCurrentAppState() {
    // save current state for application of selected participant
    const currentApp = this.applications.find(
      (item: any) =>
        item.eventId == this.app.eventID &&
        item.pTypeId == this.app.pTypeID &&
        item.profileId == this.app.profileID
    );
    if (!currentApp) return;

    currentApp.appMenu = this.siderbar.map((item: any) => ({
      ...item,
      percentComplete: 100,
      userData: !(item.isSystem || false)
        ? JSON.stringify(item.formRenderInstance.userData)
        : "[]",
    }));
    currentApp.app = this.app;
    currentApp.totalFunds = this.totalFunds;
    currentApp.addOns = this.addOns;
    currentApp.dynamicAdditionalCosts = this.dynamicAdditionalCosts;
    currentApp.addOnsTotalFormatted = this.addOnsTotalFormatted;
    currentApp.addOnsTotalMonthly = this.addOnsTotalMonthly;
    currentApp.addOnsTotalMonthlyFormatted = this.addOnsTotalMonthlyFormatted;
    currentApp.selectedAddOns = this.selectedAddOns;
    currentApp.registrationStep = this.registrationStep;
    currentApp.roommate = this.roommate;
    currentApp.camStoreFund = this.camStoreFund;
    currentApp.appliedDiscount = this.financialSummary.appliedDiscount || [];
    return currentApp;
  }

  async getPaymentInfo(selectedItems: any = []) {
    this.paymentInfo = {};
    this.paymentType = "";
    let applications = this.applications;
    if (selectedItems.length > 0) {
      applications = selectedItems;
    }

    if (
      applications.length == 0 ||
      applications.find((item: any) => (item.done || false) == false)
    ) {
      return;
    }

    try {
      const eventIDs = applications.map((item: any) => item.eventId);
      let totalCost = 0;
      for (const item of applications) {
        totalCost += item.participantAmount || 0;
      }

      let totalFund = 0;
      if(totalCost == 0) {
        // check if selected a fund?
        for(const item of applications) {
          totalFund += parseFloat(item.totalFunds || 0);
          for(const fund of (item.selectedAddOns || [])) {
            totalFund += fund.cost;
          }
        }
        totalFund = parseFloat(totalFund.toFixed(2));
      }

      if ((totalCost > 0 || (totalCost + totalFund > 0)) && eventIDs.length) {
        this.paymentInfoLoading = true;
        const response = await axios.post(`${dataURL}/getPaymentInfo`, {
          domain: ApiHelper.getDomain(),
          uuid: ApiHelper.getUuid(),
          familyID: this.$loggedUser.familyId,
          eventIDs: eventIDs.join(","),
          total: totalCost,
          hasDeposit: false,
          participants: applications.map((item: any) => {
            const additionalCosts = (item.selectedAddOns || []).map((item: any) => ({
              serviceName: item.serviceName,
              cost: item.cost,
              chargeMonthly: item.chargeMonthly || 0
            }));

            // add total funds
            if ((item.totalFunds || 0) > 0) {
              additionalCosts.push({
                serviceName: "Total Camp Store Funds",
                cost: parseFloat(parseFloat(item.totalFunds).toFixed(2)),
                isTotalFunds: true,
              });
            }

            return {
              eventID: item.eventId,
              pTypeID: item.pTypeId,
              total: item.participantAmount,
              additionalCosts,
            };
          }),
        });

        if (response.data.status == 1) {
          this.paymentInfo = response.data.data.paymentInfo || {};
          const balanceCredit = response.data.data.balanceCredit || 0;
          if(balanceCredit > 0) {
            this.balanceCredit = balanceCredit;
            this.balanceCreditFormatted = dollarFormat(balanceCredit);
          }

          // auto select pay full if have no payment monthly option
          if ((this.paymentInfo.recurringAmount || 0) == 0) {
            this.paymentType = "full";
          }

          // set stripe public key
          if ((this.paymentInfo.stripePublishableKey || "") != "") {
            if (typeof Stripe == "undefined") {
              // load stripe
              await $.getScript("https://js.stripe.com/v2/", function(
                data,
                textStatus,
                jqxhr
              ) {}).fail(function(jqxhr, settings, exception) {
                // console.log("Stripe load failes");
              });
            }
            Stripe.setPublishableKey(this.paymentInfo.stripePublishableKey);
          }
        }
      }
    } catch (error) {
      // console.log(error);
    } finally {
      this.paymentInfoLoading = false;
    }
  }

  showDiscountSection() {
    if(this.discountsHasCode.length == 0) return false;

    let show = false;
    for(const item of this.applications) {
      const existedDiscount = this.discountsHasCode.find((discount: any) => item.eventId == discount.eventId && item.pTypeId == discount.pTypeId);
      if(existedDiscount) {
        show = true;
        break;
      }
    }

    return show;
  }

  validateCardName() {
    // 
  }

  async payNow() {
    if (this.paying) {
      return;
    }

    if(this.applyFullDiscount()) {
      await this.doPay();
      return;
    }

    this.form.cardName.error = '';
    this.form.cardNumber.error = '';
    this.form.expMoth.error = '';
    this.form.expyear.error = '';
    this.form.cvc.error = '';
    this.form.zipcode.error = '';
    let hasError = false;
    if (this.form.cardName.value === '') {
      this.form.cardName.error = 'Name is required';
      hasError = true;
    }
    if (this.form.cardNumber.value === '') {
      this.form.cardNumber.error = 'Card Number is required';
      hasError = true;
    }
    if (this.form.expMoth.value === '') {
      this.form.expMoth.error = 'Exp Month is required';
      hasError = true;
    }
    if (this.form.expyear.value === '') {
      this.form.expyear.error = 'Exp Year is required';
      hasError = true;
    }
    if (this.form.cvc.value === '') {
      this.form.cvc.error = 'CVC is required';
      hasError = true;
    }
    if (this.form.zipcode.value !== '' && this.form.zipcode.value.length < 5) {
      this.form.zipcode.error = 'Zipcode is invalid';
      hasError = true;
    }
    // validate
    this.paymentErrMessage = "";
    if (this.paymentType == "") {
      this.paymentErrMessage = "Please select a payment option";
      hasError = true;
    }
    if(hasError) return;

    if (this.applications.length && (this.paymentInfo.totalCost || 0) > 0) {
      var $this = this;
      $this.stripeToken = "";
      this.paying = true;
      this.cardInfoError = "";
      try {
        Stripe.card.createToken($("#stripeFrm"), async (status, response) => {
          //Stripe token failure...
          const stripeMessage = response.error?.message || "";
          if (response.error) {
            if (response.error.param == "number") {
              $this.stripeError = 2;
              this.form.cardNumber.error = 'Card Number is invalid';
              $this.cardInfoError = stripeMessage;
            } else if (response.error.param == "exp_month") {
              $this.stripeError = 3;
              this.form.expMoth.error = 'Exp Month is invalid';
              $this.cardInfoError = stripeMessage;
            } else if (response.error.param == "exp_year") {
              $this.stripeError = 4;
              this.form.expyear.error = 'Exp Year is invalid';
              $this.cardInfoError = stripeMessage;
            } else if (response.error.param == "cvc") {
              $this.stripeError = 5;
              this.form.cvc.error = 'CVC is invalid';
              $this.cardInfoError = stripeMessage;
            } else {
              $this.stripeError = 1;
              $this.paymentErrMessage = stripeMessage;
            }
          } else {
            $this.stripeToken = response.id;
            $this.stripeLast4 = response.card.last4;
            $this.stripeError = 0;
          }

          if ($this.stripeToken != "" && $this.stripeError == 0) {
            await this.doPay();
          } else {
            this.paying = false;
          }
        });
      } catch(e) {
        this.paying = false;
        ApiHelper.showErrorMessage('Your payment is unable to be accepted at this time. Please contact your camp administrator.');
      }
    }
  }

  async doPay() {
    // try {
      this.paying = true;
      this.disablePaymentForm();
      const paymentInfo = this.paymentInfo;
      if (paymentInfo.stripePublishableKey) {
        delete paymentInfo.stripePublishableKey;
      }
      const applications = JSON.parse(JSON.stringify(this.applications.filter(item => item.done || false)));
      const availableDiscounts = JSON.parse(JSON.stringify(this.availableDiscounts));

      let discounts = JSON.parse(JSON.stringify(this.appliedDiscount));
      const discountHasNoCode = discounts.find(
        (item: any) => item.discountCode == ""
      );
      discounts = discounts.filter((item: any) => item.discountCode != "");

      const requestObj = {
        callFrom: "portal",
        saveParticipant: false,
        uuid: this.$loggedUser.entityUUID,
        applications: applications.map((item: any) => {
          // let totalCost = item.participantAmount;
          const additionalCosts = [...(item.selectedAddOns || [])];
          if((item.totalFunds || 0) > 0) {
            additionalCosts.push({
              link_event_service_Id: 0,
              addonServiceId: 5,
              serviceName: "Total Camp Store Funds",
              cost: parseFloat(parseFloat(item.totalFunds).toFixed(2)),
              isFundBucket: 1,
              costFormatted: ApiHelper.dollarFormat(item.totalFunds),
              isTotalFunds: true
            });
          }
          // for(const cost of additionalCosts) {
          //   totalCost += parseFloat(cost.cost || 0);
          // }
          // totalCost = parseFloat(totalCost.toFixed(2));

          const discountInfo: any = [];
          // apply discount without discount code firstly
          if((item.discountAmount || 0) > 0 && (discountHasNoCode?.appliedFor || []).includes(item.participantId)) {
            const discountAmount = item.discountAmount;
            item.participantAmount -= discountAmount;
            item.participantAmount = parseFloat(item.participantAmount.toFixed(2));
            item.participantAmountFormatted = ApiHelper.dollarFormat(item.participantAmount);
            item.totalCost -= discountAmount;
            item.totalCost = parseFloat(item.totalCost.toFixed(2));
            item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
            if(item.discount) {
              discountInfo.push(item.discount);
            }
          }
          // apply discount if input a discount code
          if(discounts.length) {
            // const discount = availableDiscounts.find((d: any) => d.eventId == item.eventId && d.participant_typeId == item.pTypeId);
            // const discountAmount = discount?.discountAmount || 0;
            // if(discountAmount > 0 && (discount.maxUse == 0 || (discount.maxUse > 0 && discount.maxUse != discount.totalUsed))) {
            //   // apply if pass the condition
            //   item.participantAmount -= discountAmount;
            //   item.participantAmount = parseFloat(item.participantAmount.toFixed(2));
            //   item.participantAmountFormatted = ApiHelper.dollarFormat(item.participantAmount);
            //   item.totalCost -= discountAmount;
            //   item.totalCost = parseFloat(item.totalCost.toFixed(2));
            //   item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);

            //   // no send totalUsed in discountInfo
            //   const itemDiscount = {...discount};
            //   if (itemDiscount.totalUsed != undefined) {
            //     delete itemDiscount.totalUsed;
            //   }
            //   discountInfo.push(itemDiscount);

            //   // update totalUsed
            //   discount.totalUsed += 1;
            // }

            for (const discount of discounts) {
              if ((discount.eventId == item.eventId && discount.participant_typeId == item.pTypeId && (discount.appliedFor || []).includes(item.participantId)) == false) {
                continue;
              }

              const discountAmount = discount?.discountAmount || 0;
              if (
                discountAmount > 0 &&
                (discount.maxUse == 0 ||
                  (discount.maxUse > 0 &&
                    discount.maxUse > discount.totalUsed))
              ) {
                // apply if pass the condition
                item.participantAmount -= discountAmount;
                item.participantAmount = parseFloat(item.participantAmount.toFixed(2));
                item.participantAmountFormatted = ApiHelper.dollarFormat(item.participantAmount);
                item.totalCost -= discountAmount;
                item.totalCost = parseFloat(item.totalCost.toFixed(2));
                item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);

                // no send totalUsed in discountInfo
                const itemDiscount = { ...discount };
                if (itemDiscount.totalUsed != undefined) {
                  delete itemDiscount.totalUsed;
                }

                if (itemDiscount.appliedFor) {
                  // remove appliedFor info
                  delete itemDiscount.appliedFor;
                }
                discountInfo.push(itemDiscount);

                // update totalUsed
                discount.totalUsed += 1;
              }
            }
          }

          return {
            sourceType: item.sourceType || 1,
            profileid: item.profileId,
            firstName: item.firstName || "",
            lastName: item.lastName || "",
            participantID: item.participantId || 0,
            eventID: item.eventId,
            eventName: item.eventName || "",
            participantTypeID: item.pTypeId,
            participantAmount: item.participantAmount,
            pRegistrationStep: 1,
            additionalCosts,
            totalCost: item.totalCost,
            discountInfo,
          }
        }),
        type: this.paymentType,
        cardInfo: {
          token: this.stripeToken,
          cardName: this.cardName,
          number: this.cardNumber,
          expMonth: this.expMoth,
          expYear: this.expyear,
          zipCode: this.zipcode,
          last4: this.stripeLast4
        },
        paymentInfo: paymentInfo,
        mainProfileID: this.$loggedUser.id,
        familyID: this.$loggedUser.familyId,
        // availableDiscounts: this.availableDiscounts
        applyFullDiscount: this.applyFullDiscount() ? 1 : 0
      };

      const response = await axios.post(`${dataURL}/registrationComplete`, requestObj);
      if (response.data.status == 1) {
        const paymentResult = response.data.data.paymentResult;
        if (paymentResult.status == 1) {
          // remove related item in cart
          if (this.cartItems.length) {
            for (const item of this.applications) {
              this.cartItems = this.cartItems.filter((cartItem: any) => !(
                cartItem.eventID == item.eventId &&
                cartItem.profile.profileid == item.profileId &&
                cartItem.pType.participantTypeID == item.pTypeId
              ));
            }
          }
          ApiHelper.updateCartItems({ cartItems: this.cartItems });
          await ApiHelper.updateProfileStats();

          // go to registration page
          Vue.swal.fire({
            // icon: "success",
            title: 'Registration & Payment Complete',
            customClass: {
              container: "swal2-container-custom",
              confirmButton: "SecondaryColor FontColor"
            }
          });
          this.doPayDone = true;
          this.paying = false;
          this.$router.push({ name: "Registration" });
        } else {
          const message = paymentResult.message || "";
          if(message) {
            throw message;
          }
          this.paymentErrMessage = "Something went wrong with your credit card. Please check your card information and try again.";
        }
      } else {
        const message = response.data.message || "";
        if(message) {
          throw message;
        }
        this.paymentErrMessage = "Something went wrong with your credit card. Please check your card information and try again.";
      }

      this.enablePaymentForm();
      this.paying = false;
    // } catch (error) {
    //   this.paymentErrMessage = "Something went wrong with your credit card. Please check your card information and try again.";
    // } finally {
    //   this.enablePaymentForm();
    //   this.paying = false;
    // }
  }

  disablePaymentForm() {
    $("#stripeFrm input[type=text], #stripeFrm input[type=radio], #stripeFrm button").prop("disabled", true);
  }

  enablePaymentForm() {
    $("#stripeFrm input[type=text], #stripeFrm input[type=radio], #stripeFrm button").prop("disabled", false);
  }

  hideCheckoutSlideup(e: any) {
    if($(e.target).closest(".swal2-container").length || $(e.target).closest(".left-sidebar-menu").length) {
      // case click on discount alert box, or left menu
      return;
    }

    if(!$(".slideupContainer").hasClass("invisible")) {
      this.toggleSlideUp();
    }
  }

  async applyDiscount() {
    if(this.discountCode == "") return;

    if (this.applications.length > 0) {
      try {
        this.applyingDiscount = true;
        const response = await axios.post(`${dataURL}/getAvailableDiscounts`, {
          participants: this.applications.filter(item => item.done || false).map((item: any) => {
            return {
              eventID: item.eventId,
              pTypeID: item.pTypeId,
            };
          }),
          discountCode: this.discountCode,
        });
        // getPaymentInfo
        if (response.data.status == 1) {
          this.availableDiscounts = response.data.data.availableDiscounts || [];
          if(this.availableDiscounts.length) {
            const applications = JSON.parse(JSON.stringify(this.applications.filter(item => item.done || false)));
            const availableDiscounts = JSON.parse(JSON.stringify(this.availableDiscounts));

            // check if returned available discounts can apply for applications
            let canApplyDiscountCode = false;
            for(const item of applications) {
              const discount = availableDiscounts.find((d: any) => d.eventId == item.eventId && d.participant_typeId == item.pTypeId);
              if(discount) {
                canApplyDiscountCode = true;
                break;
              }
            }

            if(canApplyDiscountCode) {
              // apply auto discount
              const discountHasNoCode = this.appliedDiscount.find(
                (item: any) => item.discountCode == ""
              );
              for(const item of applications) {
                const discountAmount = item.discountAmount || 0;
                if(discountAmount > 0 && (discountHasNoCode?.appliedFor || []).includes(item.participantId)) {
                  item.participantAmount -= discountAmount;
                  item.participantAmount = parseFloat(item.participantAmount.toFixed(2));
                  item.participantAmountFormatted = ApiHelper.dollarFormat(item.participantAmount);
                  item.totalCost -= discountAmount;
                  item.totalCost = parseFloat(item.totalCost.toFixed(2));
                  item.totalCostFormatted = ApiHelper.dollarFormat(item.totalCost);
                }
              }

              // collect discounts can apply and store in appliedDiscounts
              for (const item of applications) {
                const discount = availableDiscounts.find(
                  (d: any) =>
                    d.eventId == item.eventId &&
                    d.participant_typeId == item.pTypeId
                );
                const discountAmount = discount?.discountAmount || 0;
                if (
                  discountAmount > 0 &&
                  (discount.maxUse == 0 ||
                    (discount.maxUse > 0 &&
                      discount.maxUse > discount.totalUsed))
                ) {
                  const inApplied = this.appliedDiscount.find(
                    (t: any) =>
                      t.eventId == discount.eventId &&
                      t.participant_typeId == discount.participant_typeId &&
                      t.discountCode.toLowerCase() == discount.discountCode.toLowerCase()
                  );
                  if (!inApplied) {
                    discount.appliedFor = [item.participantId];
                    this.appliedDiscount.push({ ...discount });
                  } else if (
                    !inApplied.appliedFor.includes(item.participantId)
                  ) {
                    inApplied.appliedFor.push(item.participantId);
                  }

                  // update totalUsed
                  discount.totalUsed += 1;
                }
              }

              // apply discount based on appliedDiscounts
              const discountHasCode = this.appliedDiscount.filter(
                (item: any) => item.discountCode != ""
              );
              for (const discountItem of discountHasCode) {
                const relatedSelected = applications.filter((item: any) =>
                  discountItem.appliedFor.includes(item.participantId)
                );

                const finalAppliedFor: any = [];
                for (const item of relatedSelected) {
                  const amount =
                    item.participantAmount - discountItem.discountAmount;
                  // prevent if applying discount and make amount < 0
                  if (amount >= 0) {
                    finalAppliedFor.push(item.participantId);

                    item.participantAmount -= discountItem.discountAmount;
                    item.participantAmount = parseFloat(item.participantAmount.toFixed(2));
                    // item.balance -= discountItem.discountAmount;
                    // item.balance = parseFloat(item.balance.toFixed(2));
                    item.totalCost -= discountItem.discountAmount;
                    item.totalCost = parseFloat(item.totalCost.toFixed(2));
                    item.totalCostFormatted = ApiHelper.dollarFormat(
                      item.totalCost
                    );
                  }
                }
                discountItem.appliedFor = finalAppliedFor;
              }

              // remove discounts has empty appliedFor
              this.appliedDiscount = this.appliedDiscount.filter(
                (item: any) => item.appliedFor.length
              );

              // get new paymentInfo
              await this.getPaymentInfo(applications);

              this.paymentErrMessage = "";
              const inApplied = this.appliedDiscount.find(
                (item: any) =>
                  item.discountCode.toLowerCase() == this.discountCode.toLowerCase()
              );
              if(inApplied) {
                // show success message
                this.$swal.fire({
                  // icon: "success",
                  text: `Applied discount code ${this.discountCode.toUpperCase()} successfully`,
                  confirmButtonText: "OK",
                  showCloseButton: true,
                  customClass: {
                    container: "swal2-container-custom",
                    confirmButton: "btn button SecondaryColor FontColor"
                  }
                });
              } else {
                this.$swal.fire({
                  // icon: "warning",
                  html: `Cannot apply discount code ${this.discountCode.toUpperCase()}`,
                  confirmButtonText: "OK",
                  showCloseButton: true,
                  customClass: {
                    container: "swal2-container-custom",
                    confirmButton: "btn button SecondaryColor FontColor"
                  }
                });
              }
              // clear inputted discount code
              this.discountCode = "";
            }else {
              // cannot apply this discount code for event/ptype in selectedToPay
              this.paymentErrMessage = "Discount code not found";
            }
          }
        }else {
          const errorCode = response.data.errorCode || "";
          if (errorCode == "DISCOUNT_NOT_FOUND") {
            this.paymentErrMessage = "Discount code not found";
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.applyingDiscount = false;
      }
    }
  }

  async acceptRoomateRequest(accepted = true, roomateItem: any = {}) {
    if(this.roomateRequestIsDisabled()) {
      return;
    }

    try {
      this.loading = true;
      const requestObject: any = {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        eventID: this.app.eventID,
        profileID: this.app.profileID,
        participantID: this.app.participantID,
        acceptInviteCode: this.inviteData.code,
        accepted,
      };

      // more info incase accept/reject a request on list "You've been invited by"
      if((roomateItem.pTypeId || 0) > 0) {
        requestObject.pTypeId = roomateItem.pTypeId;
      }
      if((roomateItem.inviteCode || "") != "") {
        requestObject.acceptInviteCode = roomateItem.inviteCode;
      }
      if((roomateItem.requestId || 0) > 0) {
        requestObject.roommateRequestId = roomateItem.requestId;
      }

      const response = await axios.post(`${dataURL}/saveEventCabins`, requestObject);
      if(response.data.status == 1) {
        const inRoomMate = this.roommate.find(item => 
          item.inviteCode == this.inviteData.code
        );
        if(accepted) {
          if((roomateItem.pTypeId || 0) > 0) {
            roomateItem.link_active = 1;
          }
          this.inviteData.linkActive = 1;
          if(inRoomMate) {
            inRoomMate.link_active = 1
          }
          // ApiHelper.showSuccessMessage('Accepted the invitation successfully');
        }else {
          // message for reject a request
          roomateItem.link_active = 2;
          this.inviteData.linkActive = 2;
          if(inRoomMate) {
            inRoomMate.link_active = 2;
          }
          // ApiHelper.showSuccessMessage('You rejected the invitation');
        }
      }else if(response.data.message != "") {
        ApiHelper.showErrorMessage(response.data.message);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async removeParticipant(participant: any) {
    // do remove
    const response = await axios.post(`${dataURL}/removeParticipant`, {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      participantID: participant,
      familyID: this.$loggedUser.familyId
    });
    if (response.data.status == 1) {
      await this.updateCart(false, true);
    }
  }

  stripTags(value: string) {
    return ApiHelper.stripTags(value);
  }

  scrollToFirstError() {
    const currentSection = $("section.app-module[data-entityappmoduleid=" + (this.currentAppModuleId || 0) + "]");
    let firstError: any = null;
    if(currentSection.length && currentSection.find(".text-danger").length) {
      // if current section has errors, scroll to current section
      firstError = currentSection.find(".text-danger").first();
    }else {
      // specify first error item in application
      firstError = $(".app-modules-content .text-danger").first();
    }
    if(firstError && firstError.length) {
      $('html, body').animate({
        scrollTop: firstError.offset().top - 10
      }, 300);
    }
  }
  
  nextSection(){
    if (this.activeTabIndex < this.tabs.length) {
      this.activeTabIndex = this.activeTabIndex + 1;
    }
  }

  prevSection(){
    if (this.activeTabIndex > 0) {
      this.activeTabIndex = this.activeTabIndex - 1;
    }
  }

  makesvg(percentage, currentTab, totalTabs){
    if($(".circle-chart__circle").length){
      $(".circle-chart__percent").html(currentTab + " of " + totalTabs);
      var changeDirection = '';
      var step: any = '';
      var remainingPercent = 100 - Math.abs(percentage);
      var originalPercent: number = parseFloat($(".circlechart").attr("data-percentage"));
      var newPercent: number = 100 - remainingPercent;

      if(newPercent != originalPercent){
        changeDirection = newPercent > originalPercent ? "positive" : "negative";
      }
      else{
        changeDirection = "same";
      }

      if(newPercent == 100){
        remainingPercent = 100 - originalPercent;
        changeDirection = "positive";
      }

      if(changeDirection != "same"){
        for(let i = 1; i <= Math.floor(remainingPercent); i++){
          (function(i){
              setTimeout(function(){
                if((remainingPercent-i) < 1 && (remainingPercent-i) > 0 ){
                  step = 1 + (remainingPercent-i);
                }
                else {
                  step = 1;
                }

                if(changeDirection == "positive"){
                  originalPercent = originalPercent + step;
                }
                else{
                  originalPercent = originalPercent - step;
                }

                $(".circle-chart__circle").attr("stroke-dasharray",originalPercent+',100');
                $(".circlechart").attr("data-percentage",originalPercent);
              }, 35 * i);
          }(i));
        } 
      }
    }
    else{
      var svg = '<div class="numAdjust"><svg class="circle-chart" viewbox="0 0 33.83098862 33.83098862" xmlns="http://www.w3.org/2000/svg"><circle class="circle-chart__circle stroke" stroke-dasharray="'+Math.abs(percentage).toString()+',100" cx="16.9" cy="16.9" r="15.9" /></svg><g class="circle-chart__info"><text class="circle-chart__percent" x="17.9" y="20.5">'+currentTab+' of '+totalTabs+'</text></g></div>';
      
      return svg
    }
  }

  roomateRequestIsDisabled() {
    if(
      // if denied
      (this.app.participantStatus || 0) == ParticipantStatus.Denied || 
      // if when processing
      this.inviteData.isProcessing || 
      // if is prior event
      (this.app.isPriorEvent || 0) == 1) {
      return true;
    }
    
    return false;
  }

  getRoommateAddress(city: string, state: string) {
    const ret: any = [];
    if (city) {
      ret.push(city);
    }
    if (state) {
      ret.push(state);
    }

    return ret.join(", ");
  }

  getStateOptions() {
    return ApiHelper.getStateOptions();
  }

  showAppliedDiscount() {
    if(this.appliedDiscount.length) {
      return true;
    }
    return false;
  }

  getAppliedDiscountInfo() {
    if(this.showAppliedDiscount()) {
      // group by discountCode
      const grouped: any = [];
      for(const item of this.appliedDiscount) {
        const discountCode = (item.discountCode || "").toUpperCase();
        const inGrouped = grouped.find(d => (d.discountCode || "") == discountCode);
        if(inGrouped) {
          continue;
        }
        grouped.push({
          discountCode,
          items: this.appliedDiscount.filter(d => (d.discountCode || "").toUpperCase() == discountCode)
        });
      }

      let codes: any = [];
      for(const group of grouped) {
        const discountCode = group.discountCode;
        let totalAmount = 0;
        for(const discount of group.items) {
          const discountAmount = discount.discountAmount || 0;
          if(discountCode == "") {
            totalAmount += discountAmount;
          }else {
            totalAmount += discountAmount * (discount.appliedFor || []).length;
          }
          // totalAmount += discount.discountAmount || 0;
        }
        const discountAmountFormatted = dollarFormat(totalAmount);
        codes.push(`<strong>${discountCode}</strong> (-${discountAmountFormatted})`);
      }

      return `Applied discount: ${codes.join(", ")}`;
    }

    return "";
  }

  getParticipantTotalCost(item: any) {
    // const discount = this.autoDiscounts.find(discount => discount.eventId == item.eventId && discount.participant_typeId == item.pTypeId);
    // if(discount) {
    //   const discountAmount = discount.discountAmount || 0;
    //   if(discountAmount > 0) {
    //     const totalCost = item.totalCost - discountAmount;
    //     return ApiHelper.dollarFormat(totalCost);
    //   }
    // }
    // return item.totalCostFormatted;

    let ret = "";
    if(this.isCurrentApp(item)) {
      ret = this.getTotalBalance().totalFormatted;
    }else {
      const { eventId, pTypeId, profileId } = item;
      ret = this.getTotalBalance({ eventId, pTypeId, profileId }).totalFormatted;
    }

    return ret;
  }

  applyFullDiscount() {
    if((this.paymentInfo.totalCost || 0) == 0) {
      return true;
    }
    return false;
  }

  getAddonChargeInfo() {
    const addons = JSON.parse(JSON.stringify([...this.addOns, ...this.dynamicAdditionalCosts]));
    const selected = addons.filter(item => this.inSelectedAddOns(item));
    let firstCharge = 0;
    let recurringCharge = 0;
    for(const item of selected) {
      if((item.chargeMonthly || 0) == 0) {
        firstCharge += parseFloat(item.cost);
      }else {
        firstCharge += item.costPaymentInfo.firstCharge;
        recurringCharge += item.costPaymentInfo.recurringAmount;
      }
    }
    firstCharge = parseFloat(firstCharge.toFixed(2));
    recurringCharge = parseFloat(recurringCharge.toFixed(2));

    return {
      firstCharge,
      firstChargeFormatted: dollarFormat(firstCharge),
      recurringCharge,
      recurringChargeFormatted: dollarFormat(recurringCharge)
    };
  }

  getPayBtnText() {
    if((this.app.isWaitList || false) && !this.app.ignoreWaitlist) {
      return "Apply to Waitlist";
    }

    let ret = "Save Progress";

    let notFinishedApp = this.applications.filter(
      (item: any) =>
        // not current app
        !(item.eventId == this.app.eventID && item.pTypeId == this.app.pTypeID && item.profileId == this.app.profileID) &&
        // not done
        (item.done || false) == false
    );

    if(this.totalPercent >= 100) {
      // show "submit" if ptype cost = 0, and total cost for this app = 0
      if(this.isFreeRegistration) {
        return "Submit";
      }

      if(this.finishedApps() || (this.isEndApp() && this.haveDoneApps())) {
        ret = "Checkout";
      }else {
        ret = "Continue";
      }
    } else {
      // this app not finished yet, it is "save progress"
      if(notFinishedApp.length) {
        if(!this.isEndApp()) {
          // if still have next app need to fill, it is "save progress and continue"
          ret = "Save Progress And Continue";
        }
      } else if (this.isEndApp() && this.haveDoneApps()) {
        // allow checkout for some app if this is end, and finished some app
        ret = "Checkout";
      }
    }

    return ret;
  }

  dollarFormat(val: number) {
    return ApiHelper.dollarFormat(val);
  }

  getRegistrationSummary() {
    // 1 CAMPER, 12-17 YEARS OLD, MALE
    const ret: string[] = [];
    if(this.app.pTypeName) {
      ret.push(`1 ${this.app.pTypeName}`);
    }
    
    if (moment(this.birthday).isValid()) {
      const age = moment().diff(moment(this.birthday), "years");
      if(age > 0) {
        ret.push(`${age} years old`);
      }
    }
    
    if(this.app.pGender) {
      ret.push(ApiHelper.getGenderName(this.app.pGender));
    }
    
    return ret.join(", ");
  }

  getTotalBalance(appInfo?: {eventId: number; pTypeId: number; profileId: number}) {
    // current app
    let total = this.app.participantCost;
    let selectedAddOns = this.selectedAddOns || [];
    let totalFunds = parseFloat(this.totalFunds) || 0;
    let totalDiscount = this.summaryAppliedDiscounts(true).total || 0;

    // not current app
    if((appInfo?.eventId || 0) > 0) {
      const app = this.getApp({
        eventId: appInfo?.eventId || 0,
        pTypeId: appInfo?.pTypeId || 0,
        profileId: appInfo?.profileId || 0
      });
      if(!app) {
        return {
          total: 0,
          totalFormatted: dollarFormat(0)
        }
      }

      total = app.participantAmount || 0;
      selectedAddOns = app.selectedAddOns || [];
      totalFunds = parseFloat(app.totalFunds) || 0;
      totalDiscount = 0;
      for(const item of (app.appliedDiscount || [])) {
        totalDiscount += item.discountAmount;
        totalDiscount = parseFloat(totalDiscount.toFixed(2));
      }
    }

    let totalAdditionalCost = 0;
    for(const item of selectedAddOns) {
      totalAdditionalCost += parseFloat(item.cost);
    }
    // add ons
    totalAdditionalCost = parseFloat(totalAdditionalCost.toFixed(2));
    total += totalAdditionalCost;
    total = parseFloat(total.toFixed(2));
    // camp store funds
    if(totalFunds > 0) {
      total += totalFunds;
      total = parseFloat(total.toFixed(2));
    }
    // discounts
    if(totalDiscount > 0) {
      total -= totalDiscount;
      total = parseFloat(total.toFixed(2));
    }

    return {
      total,
      totalFormatted: dollarFormat(total)
    };
  }

  summaryAppliedDiscounts(includeAuto = false) {
    let items: string[] = [];
    let total = 0;

    let appliedDiscount = this.financialSummary.appliedDiscount;
    if(!includeAuto) {
      appliedDiscount = appliedDiscount.filter(item => item.discountCode != "");
    }
    for(const item of appliedDiscount) {
      items.push(`"${item.discountCode || item.discountName}"`);
      total += item.discountAmount * item.appliedFor.length
    }
    total = parseFloat(total.toFixed(2));
    return {
      items: `${items.join(", ")}`,
      total,
      totalFormatted: `${total > 0 ? "-" : ""}${dollarFormat(total)}`
    };
  }

  async previewDiscount() {
    const discountCode = this.financialSummary.discountCode || "";
    if(discountCode == "") return;

    if (this.applications.length > 0) {
      try {
        this.applyingDiscount = true;
        let appliedDiscount = this.financialSummary.appliedDiscount;
        const currentApp = this.getCurrentApp();
        const response = await axios.post(`${dataURL}/getAvailableDiscounts`, {
          participants: [{
            eventID: currentApp.eventId,
            pTypeID: currentApp.pTypeId,
          }],
          discountCode,
        });
        // getPaymentInfo
        if (response.data.status == 1) {
          this.financialSummary.availableDiscounts = response.data.data.availableDiscounts || [];
          if(this.financialSummary.availableDiscounts.length) {
            // const applications = JSON.parse(JSON.stringify(this.applications));
            const availableDiscounts = JSON.parse(JSON.stringify(this.financialSummary.availableDiscounts));
            const app = JSON.parse(JSON.stringify(currentApp));

            // check if returned available discounts can apply for applications
            let canApplyDiscountCode = false;
            // for(const item of applications) {
              const discount = availableDiscounts.find((d: any) => d.eventId == app.eventId && d.participant_typeId == app.pTypeId);
              if(discount) {
                canApplyDiscountCode = true;
                // break;
              }
            // }

            if(canApplyDiscountCode) {
              // apply auto discount
              const discountHasNoCode = appliedDiscount.find(
                (item: any) => item.discountCode == ""
              );
              // for(const item of applications) {
                const discountAmount = app.discountAmount || 0;
                if(discountAmount > 0 && (discountHasNoCode?.appliedFor || []).includes(app.participantId)) {
                  app.participantAmount -= discountAmount;
                  app.participantAmount = parseFloat(app.participantAmount.toFixed(2));
                  app.participantAmountFormatted = ApiHelper.dollarFormat(app.participantAmount);
                  app.totalCost -= discountAmount;
                  app.totalCost = parseFloat(app.totalCost.toFixed(2));
                  app.totalCostFormatted = ApiHelper.dollarFormat(app.totalCost);
                }
              // }

              // collect discounts can apply and store in appliedDiscounts
              // for (const item of applications) {
                const discount = availableDiscounts.find(
                  (d: any) =>
                    d.eventId == app.eventId &&
                    d.participant_typeId == app.pTypeId
                );
                // const discountAmount = discount?.discountAmount || 0;
                if (
                  (discount?.discountAmount || 0) > 0 &&
                  (discount.maxUse == 0 ||
                    (discount.maxUse > 0 &&
                      discount.maxUse > discount.totalUsed))
                ) {
                  const inApplied = appliedDiscount.find(
                    (t: any) =>
                      t.eventId == discount.eventId &&
                      t.participant_typeId == discount.participant_typeId &&
                      t.discountCode.toLowerCase() == discount.discountCode.toLowerCase()
                  );

                  if (!inApplied) {
                    discount.appliedFor = [app.participantId];
                    appliedDiscount.push({ ...discount });
                  } else if (
                    !inApplied.appliedFor.includes(app.participantId)
                  ) {
                    inApplied.appliedFor.push(app.participantId);
                  }

                  // update totalUsed
                  discount.totalUsed += 1;
                }
              // }

              // apply discount based on appliedDiscounts
              const discountHasCode = appliedDiscount.filter(
                (item: any) => item.discountCode != ""
              );
              for (const discountItem of discountHasCode) {
                // const relatedSelected = applications.filter((item: any) =>
                //   discountItem.appliedFor.includes(item.participantId)
                // );

                const finalAppliedFor: any = [];
                // for (const item of relatedSelected) {
                  const amount =
                    app.participantAmount - discountItem.discountAmount;
                  // prevent if applying discount and make amount < 0
                  if (amount >= 0) {
                    finalAppliedFor.push(app.participantId);
                    app.participantAmount -= discountItem.discountAmount;
                    app.participantAmount = parseFloat(app.participantAmount.toFixed(2));
                    app.totalCost -= discountItem.discountAmount;
                    app.totalCost = parseFloat(app.totalCost.toFixed(2));
                    app.totalCostFormatted = ApiHelper.dollarFormat(
                      app.totalCost
                    );
                  }
                // }
                discountItem.appliedFor = finalAppliedFor;
              }

              // remove discounts has empty appliedFor
              appliedDiscount = appliedDiscount.filter(
                (item: any) => item.appliedFor.length
              );
              this.financialSummary.appliedDiscount = appliedDiscount;

              // get new paymentInfo
              // await this.getPaymentInfo(applications);

              // this.paymentErrMessage = "";
              const inApplied = appliedDiscount.find(
                (item: any) =>
                  item.discountCode.toLowerCase() == discountCode.toLowerCase()
              );
              if(inApplied) {
                // update total cost after apply discount 
                // setTimeout(() => {
                //   let index = 0;
                //   let newTotal = 0;
                //   $(".participants-slide-item").each(() => {
                //     index++;
                //     if (typeof $(".participants-slide-item:nth-of-type("+index+")").find(".p-event-totalcost").html() != "undefined"){
                //       newTotal = newTotal + parseFloat(($(".participants-slide-item:nth-of-type("+index+")").find(".p-event-totalcost").html()).replace('$', '').replace(',', '')); 
                //     } 
                //   });
                //   if (!newTotal) {
                //     newTotal = parseFloat(($(".balanceContainer .balanceTotal").html()).replace('$', '').replace(',', ''));
                //   }
                //   $(".application_total_cost .total_cost").html(ApiHelper.dollarFormat(newTotal));
                // },100);

                // show success message
                this.$swal.fire({
                  // icon: "success",
                  text: `Applied discount code ${discountCode.toUpperCase()} successfully`,
                  confirmButtonText: "OK",
                  showCloseButton: true,
                  customClass: {
                    container: "swal2-container-custom",
                    confirmButton: "btn button SecondaryColor FontColor"
                  }
                });
              } else {
                this.$swal.fire({
                  // icon: "warning",
                  html: `Cannot apply discount code ${this.discountCode.toUpperCase()}`,
                  confirmButtonText: "OK",
                  showCloseButton: true,
                  customClass: {
                    container: "swal2-container-custom",
                    confirmButton: "btn button SecondaryColor FontColor"
                  }
                });
              }
              // clear inputted discount code
              this.financialSummary.discountCode = "";
            }else {
              // cannot apply this discount code
              // this.paymentErrMessage = "Discount code not found";
              this.$swal.fire({
                html: `Discount code not found`,
                confirmButtonText: "OK",
                showCloseButton: true,
                customClass: {
                  container: "swal2-container-custom",
                  confirmButton: "btn button SecondaryColor FontColor"
                }
              });
            }
          }
        }else {
          const errorCode = response.data.errorCode || "";
          if (errorCode == "DISCOUNT_NOT_FOUND") {
            // this.paymentErrMessage = "Discount code not found";
            this.$swal.fire({
              html: `Discount code not found`,
              confirmButtonText: "OK",
              showCloseButton: true,
              customClass: {
                container: "swal2-container-custom",
                confirmButton: "btn button SecondaryColor FontColor"
              }
            });
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        this.applyingDiscount = false;
      }
    }
  }

  getPreAppliedDiscounts() {
    const discountHasNoCode = this.financialSummary.appliedDiscount.filter(item => item.discountCode == "");
    return discountHasNoCode;
  }

  getCurrentApp() {
    return this.applications.find(
      (item: any) =>
        item.eventId == this.app.eventID &&
        item.pTypeId == this.app.pTypeID &&
        item.profileId == this.app.profileID
    );
  }

  getApp(appInfo: {eventId: number; pTypeId: number; profileId: number}) {
    return this.applications.find(
      (item: any) =>
        item.eventId == appInfo.eventId &&
        item.pTypeId == appInfo.pTypeId &&
        item.profileId == appInfo.profileId
    );
  }

  loadAppAppliedDiscount(currentApp: any) {
    // const currentApp = this.getCurrentApp();
    if(currentApp) {
      const appliedDiscount: any = [];
      if(currentApp.discount && (currentApp.discountAmount || 0) > 0) {
        // save auto applied discounts
        const discount = currentApp.discount;
        appliedDiscount.push({
          discountId: discount.discountId,
          discountCode: discount.discountCode || "",
          discountName: discount.discountName || "",
          discountAmount: discount.discountAmount || 0,
          appliedFor: [currentApp.participantId]
        });
      }
      const pPaymentJson = currentApp.pPaymentJson || {};
      const discountInfo = pPaymentJson?.discountInfo || [];
      for(const item of discountInfo) {
        appliedDiscount.push({
          eventId: item.eventId,
          participant_typeId: item.participant_typeId,
          discountId: item.discountId,
          discountCode: item.discountCode || "",
          discountName: item.discountName || "",
          discountAmount: item.discountAmount || 0,
          appliedFor: [currentApp.participantId],
          maxUse: 0
        });
      }
      currentApp.appliedDiscount = appliedDiscount;
    }
  }

  finishedApps() {
    let ret = false;
    if(this.totalPercent == 100) {
      let notFinishedApp = this.applications.filter(
        (item: any) =>
          // not current app
          !(item.eventId == this.app.eventID && item.pTypeId == this.app.pTypeID && item.profileId == this.app.profileID) &&
          // not done
          (item.done || false) == false
      );
      if(notFinishedApp.length == 0) {
        return true;
      }
    }
    
    return ret;
  }

  async loadNextApp() {
    // load next app need to fill
    const currentApp = this.getCurrentApp();
    const notFinishedApp = this.applications.filter(
      (item: any) => (item.done || false) == false && 
        // not current app
        !(currentApp.eventId == item.eventId && currentApp.pTypeId == item.pTypeId && currentApp.profileId == item.profileId)
    );
    let index = 0;
    let canload = false;
    for(const item of notFinishedApp) {
      if(this.currentAppPosition(item) > this.currentAppPosition()) {
        canload = true;
        break;
      }
      index += 1;
    }

    // if user fill done for all next apps, go to to end app to checkout
    if(!canload && this.applications.length > 1) {
      index = this.applications.length - 1;
      this.loadApp(index);
      return;
    }

    if (canload) {
      // load next
      this.resetParticipantInfo();
      this.participantID = notFinishedApp[index].participantId || 0;
      this.eventID = notFinishedApp[index].eventId;
      this.pTypeID = notFinishedApp[index].pTypeId;
      this.profileID = notFinishedApp[index].profileId;
      await this.fetchData();
      const currentApp = this.getCurrentApp();
      this.financialSummary.appliedDiscount = currentApp.appliedDiscount || [];
      $('html, body').scrollTop(0);
    }
  }

  currentAppPosition(selectedApp: any = null) {
    let ret = 0;
    let currentApp = this.getCurrentApp();
    if(selectedApp) {
      currentApp = selectedApp; 
    }
    let i = 0;
    for(const item of this.applications) {
      if(item.eventId == currentApp.eventId && item.pTypeId == currentApp.pTypeId && item.profileId == currentApp.profileId) {
        ret = i;
        break;
      }
      i += 1;
    }
    return ret;
  }

  prevApp() {
    const currentPos = this.currentAppPosition();
    if(currentPos == 0) return;
    this.loadApp(currentPos - 1);
  }

  nextApp() {
    const currentPos = this.currentAppPosition();
    if(currentPos == this.applications.length - 1) return;
    this.loadApp(currentPos + 1);
  }

  async loadApp(index: number) {
    const app = this.applications[index];

    this.resetParticipantInfo();
    this.participantID = app.participantId || 0;
    this.eventID = app.eventId;
    this.pTypeID = app.pTypeId;
    this.profileID = app.profileId;
    this.loading = true;
    await this.fetchData();
    const currentApp = this.getCurrentApp();
    this.financialSummary.appliedDiscount = currentApp.appliedDiscount || [];
    $('html, body').scrollTop(0);
    this.loading = false;
  }

  haveDoneApps() {
    const dones = this.applications.filter(item => (item.done || false));
    return dones.length == this.applications.length;
  }

  isEndApp() {
    if(this.currentAppPosition() == this.applications.length) {
      return true;
    }
    return false;
  }

  get doneApps() {
    return this.applications.filter(item => (item.done || false) == true);
  }

  get notDoneApps() {
    return this.applications.filter(item => (item.done || false) == false);
  }

  getTotalOfDoneApps() {
    let total = 0;
    for(const item of this.doneApps) {
      // let itemTotalCost = item.totalCost
      // const discount = this.autoDiscounts.find(discount => discount.eventId == item.eventId && discount.participant_typeId == item.pTypeId);
      // if(discount) {
      //   const discountAmount = discount.discountAmount || 0;
      //   if(discountAmount > 0) {
      //     itemTotalCost -= discountAmount;
      //     itemTotalCost = parseFloat(itemTotalCost.toFixed(2));
      //   }
      // }
      // total += itemTotalCost;

      const {eventId, pTypeId, profileId} = item;
      const appBalance = this.isCurrentApp({eventId, pTypeId, profileId}) 
          ? this.getTotalBalance().total 
          : this.getTotalBalance({ eventId, pTypeId, profileId }).total;
      total += appBalance;
      total = parseFloat(total.toFixed(2));
    }
    // total = parseFloat(total.toFixed(2));
    return {
      total,
      totalFormatted: dollarFormat(total)
    };
  }

  async cartRemoveParticipant(p: any) {
    // show warning message
    const confirm = await this.$swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to remove this registration?",
      confirmButtonText: "Confirm",
      showCloseButton: true,
      customClass: {
        container: "swal2-container-custom",
        confirmButton: "btn button SecondaryColor FontColor"
      }
    });
    if(!confirm.isConfirmed) {
      return;
    }

    // do remove
    const participantId = p.participantId || 0;
    const pStep = p.registrationStep || 0;
    if (participantId > 0) {
      const response = await axios.post(`${dataURL}/removeParticipant`, {
        domain: ApiHelper.getDomain(),
        uuid: ApiHelper.getUuid(),
        participantID: participantId,
        familyID: this.$loggedUser.familyId
      });
      if (response.data.status == 1) {
        if (pStep > 0) {
          await ApiHelper.updateProfileStats();
          const pageHeader: any = this.$refs.pageHeader;
          pageHeader.refresh();
        }
      }
    }

    if(this.isCurrentApp(p) && this.applications.length > 1) {
      // load next app if remove current app
      if(!this.isEndApp()) {
        await this.loadNextApp();
      }else {
        await this.loadApp(0);
      }
      this.loadToSlideContainsApp();
    }

    // remove in applications
    this.applications = this.applications.filter(item => !(item.eventId == p.eventId && item.pTypeId == p.pTypeId && item.profileId == p.profileId));

    // sync with cart
    const participantInCart = this.cartItems.find((item: any) => (
      item.eventID == p.eventId &&
      item.profile.profileid == p.profileId &&
      item.pType.participantTypeID == p.pTypeId
    ));
    if (participantInCart) {
      this.cartItems = this.cartItems.filter((item: any) => !(
        item.eventID == p.eventId &&
        item.profile.profileid == p.profileId &&
        item.pType.participantTypeID == p.pTypeId
      ));
      ApiHelper.updateCartItems({ cartItems: this.cartItems });
    }

    setTimeout(() => {
      if($('.carousel-item.active').length == 0) {
        $('.carousel-item').first().addClass('active');
      }
      // remove register
      // const newTotal = parseFloat(($(".application_total_cost .total_cost").html()).replace('$', '').replace(',', '')) - parseFloat(p.totalCost);
      // $(".application_total_cost .total_cost").html(ApiHelper.dollarFormat(newTotal));
    }, 100);

    if(!this.cartItems.length) {
      // back to registrations apge
      this.$router.push({ name: "Registration" });
    }
  }

  cartCheckout() {
    if(this.doneApps.length) {
      $(".registration_cart_btn.dropdown-toggle.show").click();
      this.toggleSlideUp();
    }
  }

  getCartTotal() {
    // let total = 0;
    // for(const item of this.cartItems) {
    //   let itemTotal = item.totalCost || item.pType.participantAmount || 0;
    //   const discount = this.autoDiscounts.find(discount => discount.eventId == item.eventID && discount.participant_typeId == item.pType.participantTypeID);
    //   const discountAmount = discount?.discountAmount || 0;
    //   if(discountAmount > 0) {
    //     itemTotal -= discountAmount;
    //     itemTotal = parseFloat(itemTotal.toFixed(2));
    //   }
    //   total += itemTotal;
    //   total = parseFloat(total.toFixed(2))
    // }
    // $(".application_total_cost .total_cost").html(dollarFormat(total));

    let total = 0;
    for(const item of this.cartItems) {
      const eventId = item.eventID;
      const pTypeId = item.pType.participantTypeID;
      const profileId = item.profile.profileid;
      const appBalance = this.isCurrentApp({eventId, pTypeId, profileId}) 
          ? this.getTotalBalance().total 
          : this.getTotalBalance({ eventId, pTypeId, profileId }).total;
      total += appBalance;
      total = parseFloat(total.toFixed(2));
    }

    return dollarFormat(total);
  }

  isCurrentApp(item: any) {
    const currentApp = this.getCurrentApp();
    if(!currentApp) return false;

    if(item.eventId == currentApp.eventId && item.pTypeId == currentApp.pTypeId && item.profileId == currentApp.profileId) {
      return true;
    }
    return false;
  }

  slideLoadApp(item: any) {
    const pos = this.currentAppPosition(item);
    this.loadApp(pos);
  }

  isNotDoneApp() {
    if(["save progress and continue", "save progress"].includes(this.getPayBtnText().trim().toLowerCase())) {
      return true;
    }
    return false;
  }

  get dataForSlide() {
    // 3 items per slide
    const ret: any = [];
    let putFull = false;
    for(const app of this.applications) {
      // exit if put full data for slide
      if(putFull) break;

      const pos = this.currentAppPosition(app);
      // append 3 items for this app
      const slides: any = [];
      for(let i=pos; i<pos+3; i++) {
        if(this.applications[i]) {
          slides.push(this.applications[i]);

          // mark put full at this point
          if(i == this.applications.length-1) {
            putFull = true;
          }
        }
      }
      ret.push({
        ...app,
        slides: slides
      });
    }

    return ret;
  }

  loadToSlideContainsApp(app: any = null) {
    if(this.applications.length <= 3) return;

    let toApp = app ? app : this.getCurrentApp();
    // exit if current slide contains this app
    let inCurrentSlide = false;
    $(".carousel-item.active .participants-slide-item").each((i, el) => {
      const data = $(el).data();
      if(data.eventid == toApp.eventId && data.ptypeid == toApp.pTypeId && data.profileid == toApp.profileId) {
        inCurrentSlide = true;
      }
    });
    if(inCurrentSlide) {
      return;
    }

    // find cloest slide contain this app
    let index = 0;
    $(".carousel-item").each((e, el) => {
      index = e;
      let inThisSlide = false;
      $(el).find(".participants-slide-item").each((i, slideItem) => {
        const data = $(slideItem).data();
        if(data.eventid == toApp.eventId && data.ptypeid == toApp.pTypeId && data.profileid == toApp.profileId) {
          inThisSlide = true;
        } 
      });
      if(inThisSlide) {
        return false;
      }
    });
    const slide = bootstrap.Carousel.getInstance($("#participants-slide").get(0));
    if(slide) {
      slide.to(index);
    }
  }

  // async reloadApp() {
  //   const cartItems = await ApiHelper.syncCartItems();
  //   this.autoDiscounts = cartItems?.data?.autoDiscounts || [];
  //   this.cartItems = await ApiHelper.loadSavedCart();
  //   await this.updateCart();
  // }

  newGuardian = {
    name: '',
    nameError: '',
    guardianTypeId: 6,
    relationTypeId: 5,
    type: 0,
    email: '',
    emailError: '',
    phoneNumber: '',
    phoneNumberError: '',
    typeOptions: ApiHelper.getGuardianTypeOptions(),
  };
  isProcessing = false;
  updatingGuardians = false;
  async toggleGuardian(profile: any) {
    if (this.updatingGuardians) {
      return false;
    }
    const isGuardian = !profile.isGuardian;
    profile.isGuardian = isGuardian;
    this.updatingGuardians = true;
    const result = await ApiHelper.apiPost("/updateProfileGuardians", {
    // const result = await ApiHelper.apiPost("/updateProfile", {
      domain: ApiHelper.getDomain(),
      uuid: ApiHelper.getUuid(),
      // profileID: profile.profileid,
      selectedProfileID: profile.profileid,
      isGuardian: isGuardian,
      guardians: this.members.filter((item: any) => item.isGuardian).map((item: any) => item.profileid),
      profileID: this.app.profileID,
      mainProfileID: this.$loggedUser.id,
      familyID: this.$loggedUser.familyId,
    });
    this.updatingGuardians = false;
    if (result.status == 1) {
      // nothing
      this.getTotalPercent();
    } else {
      ApiHelper.showErrorMessage(`Can't update guardian type`);
    }
  }
  async createProfile(forceUseSameEmail = false) {
    let newProfileId = 0;
    await ApiHelper.sleep(100);
    let hasError = false;
    if (this.newGuardian.name == '') {
      hasError = true;
      this.newGuardian.nameError = 'Name is required';
    }
    if (this.newGuardian.email == '') {
      hasError = true;
      this.newGuardian.emailError = 'Email is required';
    } else if(!ApiHelper.validateEmail(this.newGuardian.email)) {
      hasError = true;
      this.newGuardian.emailError = 'Email is invalid';
    }
    if (this.newGuardian.phoneNumber == '') {
      hasError = true;
      this.newGuardian.phoneNumberError = 'Phone is required';
    }
    if (!hasError && !this.isProcessing) {
      this.newGuardian.name = ApiHelper.stripTags(this.newGuardian.name);
      const tmp = this.newGuardian.name.split(" ");
      const newFirstName = tmp[0] || '';
      const newLastName = tmp[1] || '';

      if (newFirstName && newLastName) {
        const existMember = this.members.find((item: any) => {
          return item.p_fname.toLowerCase() === newFirstName.toLowerCase()
            && item.p_lname.toLowerCase() === newLastName.toLowerCase();
        });
        let isConfirmed = true;
        if (existMember) {
          isConfirmed = await Vue.swal({
            title: "Are you sure?",
            html: `<strong>${this.newGuardian.name}</strong> has already been added are you sure you want to add them again?`,
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, do it!",
            customClass: {
              container: "swal2-container-custom",
            }
          }).then(result => {
            if (result.isConfirmed) {
              return true;
            } else {
              this.newGuardian.name = '';
              return false;
            }
          });
        }

        if (isConfirmed) {
          // this.isProcessing = true;
          const requestObject: any = {
            uuid: this.$loggedUser.entityUUID,
            fname: newFirstName,
            lname: newLastName,
            phone: this.newGuardian.phoneNumber,
            email: this.newGuardian.email,
            type: 2,
            relationTypeID: this.newGuardian.relationTypeId,
            guardianTypeID: this.newGuardian.guardianTypeId,
            mainProfileID: this.$loggedUser.id,
            familyID: this.$loggedUser.familyId,
            isGuardian: true,
            forceUseSameEmail
          };
          const response = await axios.post(`${dataURL}/createProfile`, requestObject);
          if (response.data.status == 1) {
            this.isProcessing = true;
            const resData = response.data.data || {};
            this.members.push({
              profileid: resData.profileID,
              p_fname: newFirstName,
              p_lname: newLastName,
              p_email: null,
              p_dob: ``,
              p_gender: resData.gender,
              genderName: resData.genderName,
              p_logo: "",
              relationTypeName: ApiHelper.getSubProfileTypeName(Number.parseInt(`${this.newGuardian.relationTypeId}`, 10)),
              guardianTypeName: ApiHelper.getGuardianTypeName(Number.parseInt(`${this.newGuardian.guardianTypeId}`, 10)),
              p_phone: this.newGuardian.phoneNumber,
              phoneFormatted: ApiHelper.phoneFormat(this.newGuardian.phoneNumber),
              age: resData.age,
              isGuardian: true,
              balance: 0,
              totalEvents: 0,
              activeEvents: 0,
              joinedEvents: []
            });
            this.newGuardian.name = "";
            // this.newGuardian.type = "";
            this.newGuardian.email = "";
            this.newGuardian.phoneNumber = "";

            // update total members stats
            this.$loggedUser.stats.TotalMembers += 1;
            ApiHelper.saveLoggedUser(this.$loggedUser);
            const pageHeader: any = this.$refs.pageHeader;
            pageHeader.refresh();
            newProfileId = response.data.data.profileID;
          } else {
            const errorCode = response.data.errorCode || "";
            if (errorCode == "email_existed") {
              // confirm if admin wants to use same email for profiles in same family
              const confirm = await this.$swal({
                html: response.data.message,
                // icon: "error",
                title: "Oops",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, do it!",
                customClass: {
                  container: "swal2-container-custom",
                }
              });
              if (confirm.isConfirmed) {
                // recall save function
                await this.createProfile(true);
              }
            } else {
              ApiHelper.showErrorMessage(response.data.message, "Oops");
            }
          }
        }
        this.isProcessing = false;

      } else {
        this.isProcessing = false;
        await Vue.swal({
          title: "Oops",
          html: `Your name is too short. It should include first name and last name.`,
        });
      }

    }

    return newProfileId;
  }

  async showForcePaymentNote() {
    if(this.forcePayment) {
      // warning that user need to complete app and pay to active this force pay registration
      await this.$swal.fire({
        text: "Please note your registration will not be active until your application has been completed and a payment has been made.",
        customClass: {
          container: "swal2-container-custom",
          confirmButton: "btn button SecondaryColor FontColor",
        }
      });
    }
  }

  get isShowingPayment() {
    const slideup = $(".slideupContainer");
    if(!slideup.hasClass("invisible"))  {
      return true;
    }
    return false;
  }

  get isFreeRegistration() {
    if(this.app.participantCost == 0 && this.getTotalBalance().total == 0) {
      return true;
    }
    return false;
  }

  get isCompletedFreeRegistration() {
    if(this.registrationStep == 1 && this.app.participantCost == 0) {
      return true;
    }
    return false;
  }

  async removeCurrentAppOutCart() {
    if (this.cartItems.length) {
      this.cartItems = this.cartItems.filter((cartItem: any) => !(
        cartItem.eventID == this.app.eventID &&
        cartItem.profile.profileid == this.app.profileID &&
        cartItem.pType.participantTypeID == this.app.pTypeID));
      ApiHelper.updateCartItems({
        cartItems: this.cartItems
      });
      await ApiHelper.updateProfileStats();
    }
  }

  async removeFreeApp(freeApp: {
    profileId: number,
    eventId: number,
    pTypeId: number
  }) {
    if(freeApp.profileId > 0) {
      // remove this free app out of applications
      const currentApp = this.getCurrentApp();
      this.applications = this.applications.filter(item => !(item.profileId == freeApp.profileId && item.eventId == freeApp.eventId && item.pTypeId == freeApp.pTypeId));
      if(currentApp.profileId == freeApp.profileId && currentApp.eventId == freeApp.eventId && currentApp.pTypeId == freeApp.pTypeId) {
        // load to end app
        await this.loadApp(this.applications.length - 1);
        this.loadToSlideContainsApp();
      }
    }
  }
}
