import { render, staticRenderFns } from "./Todo.vue?vue&type=template&id=04b96188&scoped=true&"
import script from "./Todo.vue?vue&type=script&lang=tsx&"
export * from "./Todo.vue?vue&type=script&lang=tsx&"
import style0 from "./Todo.vue?vue&type=style&index=0&lang=less&"
import style1 from "./Todo.vue?vue&type=style&index=1&id=04b96188&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b96188",
  null
  
)

export default component.exports