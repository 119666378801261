import { render, staticRenderFns } from "./FormPhotoToDo.vue?vue&type=template&id=1c77f388&scoped=true&"
import script from "./FormPhotoToDo.vue?vue&type=script&lang=tsx&"
export * from "./FormPhotoToDo.vue?vue&type=script&lang=tsx&"
import style0 from "./FormPhotoToDo.vue?vue&type=style&index=0&lang=less&"
import style1 from "./FormPhotoToDo.vue?vue&type=style&index=1&id=1c77f388&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c77f388",
  null
  
)

export default component.exports